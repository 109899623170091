import { useState, useEffect, useMemo, useRef, useContext, useCallback } from "react"

import { InputGroup, Table } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import AutoHideToast from "../../components/AutoHideToast"

import RightClickMenu from "../../components/RightClickMenu"

import "../../css/BidAskBar.css"
import { ParseBigNumber } from "../../functions/NumberProcess"
import { MonthSym2CallPut } from "../../functions/StockHandle"

const EmptyObj = {}

function SymbolSelect({MonthCodes, symbol, expdate})
{
    const [InputSymbol, setInputSymbol] = useState()

    useEffect(()=>{
        if (InputSymbol) InputSymbol.value = symbol.state
    }, [symbol, InputSymbol])

	const DateOption = useMemo(()=>{
		return (
			<> { MonthCodes.map((x)=>{ return <option key={x.code} value={x.code}>{x.dateStr}</option> }) } </>
		)
	}, [MonthCodes])

    return useMemo(()=>{
        return (
            <div className='mb-2' style={{display: "flex", flexWrap: "wrap", gap: 8}}>
				<InputGroup style={{flex: 1, minWidth: 200}}>
					<InputGroup.Text style={{fontSize: 16, padding: "4px 8px"}}>
						Symbol
					</InputGroup.Text>
					<Form.Control ref={(r)=>{setInputSymbol(r)}} style={{fontSize: 16, padding: "4px 8px"}}
						onBlur={(e)=>{
							symbol.setState(e.target.value)
						}}
					/>
				</InputGroup>
				<InputGroup style={{flex: 1, minWidth: 200}}>
					<InputGroup.Text style={{fontSize: 16, padding: "4px 8px"}}>
						Exp.Date
					</InputGroup.Text>
					<Form.Select value={expdate.state} style={{fontSize: 16, padding: "4px 8px"}}
						onChange={(e)=>{ expdate.setState(e.target.value) }}
					>
						{DateOption}
					</Form.Select>
				</InputGroup>
			</div>
        )
    }, [DateOption, symbol, expdate])
}

function Quote({GlobalSubscribe, AddToast, PositionData, column, symbol, strike, expdateName, quote})
{
	const CompletedSymbol = useMemo(()=>{
		return symbol.state + strike + expdateName
	}, [symbol, strike, expdateName])

	const SetGlobalSubscribe = useCallback((price)=>{
		if (typeof(price) !== "number") return false

		GlobalSubscribe.setState({time: new Date().getTime(), symbol: CompletedSymbol, price: price})
		AddToast(<AutoHideToast title="Cap Price" message={"Symbol: " + CompletedSymbol + " Price:" + price}/>)

		return true
	}, [GlobalSubscribe, AddToast, CompletedSymbol])

	const Position = useMemo(()=>{
		return PositionData[CompletedSymbol]
	}, [CompletedSymbol, PositionData])

	return useMemo(()=>{
		const TotalSize = (quote.bidS??0) + (quote.askS??0)
		const BidRatio = (quote.bidS?(quote.bidS/TotalSize):0) * 100
		const AskRatio = (quote.askS?(quote.askS/TotalSize):0) * 100

		return (
			<>
				{
					column.map((x)=>{
						if (x === "bidS")
						{
							return (
								<td key={x} className="BarContainer">
									<div style={{display: "flex", flexDirection: "row"}}>
										<div style={{flex: 1, padding: "2px 2px"}}>{quote[x]??""}</div>
										<div className="BidBar" style={{width: BidRatio+"%"}}></div>
									</div>
								</td>
							)
						}
						if (x === "askS")
						{
							return (
								<td key={x} className="BarContainer">
									<div style={{width: "100%", display: "flex", flexDirection: "row-reverse", position: "relative", padding: 0, overflow: "hidden", alignItems: "center"}}>
										<div style={{flex: 1, textAlign: "right", padding: "2px 2px"}}>{quote[x]??""}</div>
										<div className="AskBar" style={{width: AskRatio+"%"}}></div>
									</div>
								</td>
							)
						}
						if (x === "bid" || x === "ask")
						{
							return (
								<td key={x} style={{cursor: "pointer", textAlign: "center", padding: "2px 2px"}}
									onClick={(e)=>{
										e.preventDefault();

										if (!SetGlobalSubscribe(quote[x])) return

										e.target.style.backgroundColor = "rgb(255,0,0)"
										e.target.style.color = "white"
										setTimeout(()=>{
											e.target.style.backgroundColor = "inherit"
											e.target.style.color = "inherit"
										}, 500)
									}}
								>
									{quote[x]}
								</td>
							)
						}
						if (x === "volume")
						{
							return <td key={x} style={{textAlign: "right", padding: "2px 2px"}}>{ParseBigNumber(quote[x])}</td>
						}
						if (x === "pos")
						{
							return <td key={x} style={{textAlign: "right", padding: "2px 2px"}}>{(Position??{pos:0}).pos}</td>
						}
						return <td key={x} style={{textAlign: "right", padding: "2px 2px"}}>{quote[x]}</td>
					})
				}
			</>
		)
	}, [SetGlobalSubscribe, Position, column, quote])
}

function OptionMaster({onClose, context, item})
{
	const MainContext = useContext(context)

	const [setShowMenu, MonthCodes, GlobalSubscribe, AddToast, SendSubscribe, PositionData] = useMemo(()=>{
		return [MainContext.setShowMenu, MainContext.MonthCodes, MainContext.GlobalSubscribe, MainContext.AddToast, MainContext.SendSubscribe, MainContext.PositionData]
	}, [MainContext])

    const [symbol, setSymbol] = useState("HSI")
	const subscribeSymbol = useRef("")
	const [expdate, setExpDate] = useState("N2")

	useEffect(()=>{
		if (MonthCodes.length > 0)
			setExpDate(MonthCodes[0].code)
	}, [MonthCodes])

	const [mode, setMode] = useState(0)

	const symbolObj = useMemo(()=>{ return {state: symbol, setState: setSymbol} }, [symbol])
	const expdateObj = useMemo(()=>{ return {state: expdate, setState: setExpDate} }, [expdate])

    useEffect(()=>{
		subscribeSymbol.current = symbol
		
		if (symbol !== "") SendSubscribe(symbol + expdate)
	}, [symbol, expdate, SendSubscribe])

	const [CallName, PutName] = useMemo(()=>{
		const callName = MonthSym2CallPut[expdate[0]].c + expdate[1]
		const putName = MonthSym2CallPut[expdate[0]].p + expdate[1]
		return [callName, putName]
	}, [expdate])

	const BaseSpread = useCallback((price)=>{
		if (price >= 10000) return 200
		if (price > 5000) return 100
		return 50
	},[])
	const CurrPriceBase = useMemo(()=>{
		const quote = MainContext.QuoteData[symbol + expdate]
		if (!quote) return 0

		return quote.bid - (quote.bid % BaseSpread(quote.bid))
	}, [symbol, expdate, BaseSpread, MainContext.QuoteData])
	const CurrData = useMemo(()=>{
		if (symbol === "") return EmptyObj
		const quotes = {}

		Object.keys(MainContext.QuoteData).forEach((x)=>{
			if (x.startsWith(symbol))
			{
				if (x.endsWith(CallName))
				{
					const Price = x.replace(symbol, "").replace(CallName, "")
					if (!quotes[Price]) quotes[Price] = {}
					quotes[Price].c = MainContext.QuoteData[x]
				}
				else if (x.endsWith(PutName))
				{
					const Price = x.replace(symbol, "").replace(PutName, "")
					if (!quotes[Price]) quotes[Price] = {}
					quotes[Price].p = MainContext.QuoteData[x]
				}
			}
		})

		return quotes
	}, [symbol, expdate, CallName, PutName, MainContext.QuoteData])

	const TableColumns = useMemo(()=>{
		const Simple = ["quote"]
		const Basic = ["quote", "last"/*, "prevClose"*/, "high", "low", "volume"/*, "openint"*/, "pos"]
		const Advance = ["quote", "last", "delta", "gamma", "theta", "vega", "openint", "pos"]

		const targetMode = [Simple, Basic, Advance]

		const [callColumn, putColumn] = [targetMode[mode].slice().reverse(), targetMode[mode].slice()]
		callColumn.splice(-1 , 1, "bidS", "bid", "ask", "askS")
		putColumn.splice(0, 1, "bidS", "bid", "ask", "askS")

		return {callColumn, putColumn}
	}, [mode])

	const TableHeader = useMemo(()=>{
		const columnStyle= {textAlign: "center", padding: "2px 2px", width: (1/(TableColumns.callColumn.length*2+1)*100)+"%"}

		const TitleMap = {
			bidS: "B.Qty",
			bid: "Bid",
			ask: "Ask",
			askS: "A.Qty",
			last: "Last",
			prevClose: "Prv.C",
			high: "High",
			low: "Low",
			volume: "Volume",
			delta: "Delta",
			gamma: "Gamma",
			theta: "Theta",
			vega: "Vega",
			openint: "OpenInt",
			pos: "Pos"
		}

		return (
			<thead>
				<tr>
					<th style={{textAlign: "center", padding: "2px 4px", backgroundColor: "rgba(255,193,7,.2)"}} colSpan={TableColumns.callColumn.length}>CALL OPTION</th>
					<th></th>
					<th style={{textAlign: "center", padding: "2px 4px", backgroundColor: "rgba(102,16,242,.2)"}} colSpan={TableColumns.putColumn.length}>PUT OPTION</th>
				</tr>
				<tr>
					{
						TableColumns.callColumn.map((x)=>{
							return <th key={"call"+x} style={columnStyle}>{TitleMap[x]}</th>
						})
					}
					<th style={columnStyle}>Strike</th>
					{
						TableColumns.putColumn.map((x)=>{
							return <th key={"put"+x} style={columnStyle}>{TitleMap[x]}</th>
						})
					}
				</tr>
			</thead>
		)
	}, [TableColumns])

	const PriceList = useMemo(()=>{
		if (CurrPriceBase === 0) return []
		const priceList = [CurrPriceBase]

		for (let i = 0; i < 6; i++)
		{
			priceList.splice(0, 0, priceList[0] - BaseSpread(priceList[0]))
			priceList.push(priceList[priceList.length - 1] + BaseSpread(priceList[priceList.length - 1]))
		}

		return priceList
	}, [CurrPriceBase])

	const TableBody = useMemo(()=>{
		const columnStyle= {textAlign: "center", padding: "2px 2px"}

		if (PriceList.length === 0) return <></>

		return (
			<>
				{
					PriceList.map((x)=>{
						const MainQuote = CurrData[x]
						const CallQuote = MainQuote?MainQuote.c?MainQuote.c:{}:{}
						const PutQuote = MainQuote?MainQuote.p?MainQuote.p:{}:{}

						if (MainQuote === undefined)
						{
							SendSubscribe(symbol+x+CallName)
							SendSubscribe(symbol+x+PutName)
						}

						return (
							<tr key={x}>
								<Quote GlobalSubscribe={GlobalSubscribe} PositionData={PositionData} AddToast={AddToast} column={TableColumns.callColumn} symbol={symbolObj} expdateName={CallName} strike={x} quote={CallQuote}/>
								<td style={columnStyle}>{x}</td>
								<Quote GlobalSubscribe={GlobalSubscribe} PositionData={PositionData} AddToast={AddToast} column={TableColumns.putColumn} symbol={symbolObj} expdateName={PutName} strike={x} quote={PutQuote}/>
							</tr>
						)
					})
				}
			</>
		)
	}, [GlobalSubscribe, SendSubscribe, AddToast, PositionData, symbol, symbolObj, CallName, PutName, TableColumns, PriceList, BaseSpread, CurrData])

	const touching = useRef(false)
	const onShowMenu = useCallback((e)=>{
		setShowMenu(
			<RightClickMenu MenuName='OptionMaster Menu' setShowMenu={setShowMenu} event={e}
				touching={touching}
				ButtonNames={["Simple Mode", "Normal Mode"/*, "Advance Mode"*/]}
				ButtonOnClick={[
					()=>{setMode(0)},
					()=>{setMode(1)},
					/*()=>{setMode(2)}*/
				]}
			/>
		)
	}, [setShowMenu, touching])

    return useMemo(()=>{
        return (
            <div style={{display: "flex", width: "100%", height: "100%", flexDirection: "column", fontSize: 14}}>
				<SymbolSelect MonthCodes={MonthCodes} symbol={symbolObj} expdate={expdateObj}/>
				<div style={{flex: 1, width: "100%", overflow: "auto"}}>
					<Table bordered hover style={{margin: 0, minWidth: "100%"}}
						onContextMenu={(e)=>{
							e.preventDefault();
							e.stopPropagation();
		
							if (e.button === -1) return
							onShowMenu(e)
						}}
						onTouchEnd={()=>{touching.current = false}}
						onTouchStart={(e)=>{
							e.stopPropagation();
		
							if (e.touches.length === 2)
							{
								touching.current = true
								onShowMenu(e)
							}
						}}
					>
						{TableHeader}
						<tbody>
							{TableBody}
						</tbody>
					</Table>
				</div>
			</div>
        )
    }, [onShowMenu, symbolObj, expdateObj, TableHeader, TableBody])
}

export default OptionMaster
