class WebSocketHandle{
    constructor(){
        this.isWSS = window.location.protocol === "https:"
        this.host = window.location.hostname
        this.port = window.location.hostname==="localhost"?19000:window.location.hostname==="172.25.9.150"?19000:0
        this.path = "ws"

        this.fullHost = (this.isWSS?"wss://":"ws://") + this.host + (this.port===0?"":(":"+this.port)) + "/" + this.path;
        this.timeout = 6000

        this.isClose = false;
        this.isReconnect = false;
        this.connectTimer = null;
        this.heartbeatTimer = null;
        this.recvPing = true;

        this.resendWhenReconnect = []
    }

    connect(setWS, setConnStatus, onMessage){
        this.setWS = setWS
        this.setConnStatus = setConnStatus
        this.onMessage = onMessage
        this.reconnect();
    }

    reconnect(){
        if (this.connectTimer)
            clearInterval(this.connectTimer)
        if (this.heartbeatTimer)
            clearInterval(this.heartbeatTimer)
        
        this.ws = new WebSocket(this.fullHost);
        window.ws = this.ws

        this.ws.onmessage = (data) => {
            this.recvPing = true;
            if (data.data !== "")
                this.onMessage(data.data)
        };

        this.ws.onopen = () => {
            console.log(new Date(), " >> Websocket Connected >> ")

            this.setConnStatus(true)
            this.setWS(this)

            if (this.connectTimer){
                this.connectTimerCount = 0
                clearInterval(this.connectTimer)
            }

            this.recvPing = true
            this.heartbeatTimer = setInterval(()=>{
                if (this.recvPing){
                    this.recvPing = false
                    this.ws.send("")
                }else{
                    console.log(new Date(), " >> Connection Lost >> ");
                    this.ws.onclose()
                }
            }, this.timeout)
        }

        this.ws.onclose = () => {
            if (!this.isClose){
                console.log(new Date(), " >> Try Reconnect >> ")
                this.setConnStatus(false)
                this.isReconnect = true
                if (this.connectTimerCount === 0)
                    this.reconnect();
            }else{
                console.log(new Date(), " >> Websocket Disconnected >> ")
            }
        }

        this.connectTimerCount = this.timeout - 1000
        this.connectTimer = setInterval(()=>{
            if (this.connectTimerCount === 0){
                if (this.ws.readyState !== this.ws.OPEN){
                    console.log(new Date(), " >> Connection Timeout >> ");
                    this.isReconnect = true
                    this.reconnect();
                }
            }else{
                this.connectTimerCount = this.connectTimerCount - 1000
            }
            this.setConnStatus((old)=>{
                if (!this.isReconnect)
                    return old
                return this.connectTimerCount
            })
        }, 1000)

        document.onclose = this.close;
    }

    send(msg, needResend = false){
        if (this.resendWhenReconnect.indexOf(msg) === -1 || needResend === false){
            if (needResend)
                this.resendWhenReconnect.push(msg)
            if (this.ws.readyState === this.ws.OPEN)
                this.ws.send(msg);
        }
    }

    resend(){
        this.resendWhenReconnect.forEach((x)=>{
            this.ws.send(x)
        })
    }

    close(){
        this.isClose = true
        this.ws.close()
    }
}

export { WebSocketHandle }