import { useState, useEffect, useMemo } from "react"

import { InputGroup } from "react-bootstrap"
import Form from "react-bootstrap/Form"

function SymbolSelect({symbol, lockSymbol, minWidth=null, padding="4px 8px"})
{
    const [InputSymbol, setInputSymbol] = useState()

    useEffect(()=>{
        if (InputSymbol) InputSymbol.value = symbol.state
    }, [symbol, InputSymbol])

    return useMemo(()=>{
        return (
            <div className='mb-2' style={{display: "flex", flexWrap: "wrap", gap: 8}}>
				<InputGroup style={{flex: 1, minWidth: 200}}>
					<InputGroup.Text style={{fontSize: 14, padding: padding, minWidth: minWidth}}>
						Symbol
					</InputGroup.Text>
					<Form.Control ref={(r)=>{setInputSymbol(r)}} style={{fontSize: 14, padding: padding}}
						onBlur={(e)=>{ e.target.value = symbol.state }}
                        onKeyPress={(e)=>{
                            if (e.key === "Enter")
                                symbol.setState(e.target.value)
                        }}
					/>
                    <InputGroup.Text style={{fontSize: 14, padding: padding}}>
						<Form.Check value={lockSymbol.state} onChange={()=>{
							lockSymbol.setState((old)=>!old)
						}}/>&nbsp;Lock
					</InputGroup.Text>
				</InputGroup>
			</div>
        )
    }, [minWidth, padding, symbol, lockSymbol])
}

export default SymbolSelect