class Message{
    constructor(time, message){
        this.time = new Date(time)
        this.message = message
    }
}

class Order{
    constructor(order){
        this.time = typeof(order.time)==="object"?order.time:new Date(order.time)
        this.oid = order.oid
        this.symbol = order.symbol
        this.orderType = order.orderType
        this.side = order.side
        this.price = order.price
        this.volume = order.volume
        this.expireType = order.expireType
        this.days = order.days
    }
}

class Trade{
    constructor({time, symbol, side=null, price, volume, oid=null}){
        this.time = typeof(time)==="object"?time:new Date(time)
        this.oid = oid
        this.symbol = symbol
        this.side = side
        this.price = price
        this.volume = volume
        this.pnl = 0
    }
}

class Kline{
    constructor(){
        this.symbol = ""
        this.time = new Date()
        this.open = 0
        this.close = 0
        this.high = 0
        this.low = 0
        this.volume = 0
    }
}

export {Message, Order, Trade, Kline}