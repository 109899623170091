import { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';

import { WebSocketHandle } from '../WebSocket';

import { StatusType } from "../functions/API"

import QRCode from 'qrcode.react';
import Card from 'react-bootstrap/Card'

function QRCodeLoginPage(){
    const { reason } = useParams()

    const [ws, setWS] = useState(null)
    const [connState, setConnState] = useState(false)

    const [sessionKey, setSessionKey] = useState("")
    const [errMsg , setErrMsg] = useState("")

    useEffect(()=>{
        if (!ws){
            let websocket = new WebSocketHandle(window.location.hostname==="localhost"?"alpha-trader.local":window.location.hostname, "ws", 0, window.location.protocol==="https:"?true:false);
            websocket.connect(setWS, setConnState, (msg)=>{
                const jsonData = JSON.parse(msg.data)
                if (jsonData.e === "getDeviceKey"){
                    setSessionKey(jsonData.message)
                }
                else if (jsonData.e === "login"){
                    if (jsonData.status === StatusType.Success){
                        localStorage.userID = jsonData.userID
                        window.location = "/main"
                    }
                    else if (jsonData.status === StatusType.Error){
                        setErrMsg("App's Username or Password Wrong!")
                    }
                    else if (jsonData.status === StatusType.ServerTimeout){
                        setErrMsg("Server Timeout.")
                    }
                }
            })
        }
    }, [ws])

    useEffect(()=>{
        if (ws && connState === true){
            ws.send(JSON.stringify({e: "getDeviceKey"}))
            setErrMsg("")
        }else if (connState !== true){
            setSessionKey("")
            setErrMsg("Server isn't connected." + (connState===false?"":connState>0?" Retry in "+connState/1000+" seconds.":" Retry!"))
        }
    }, [ws, connState])

    return (
        <Card style={{margin: "5em auto", maxWidth: 450}}>
            <Card.Header className="bg-primary text-white">
                <span className="fs-3">QRCode Login</span>
            </Card.Header>
            <Card.Body>
                <div style={{margin: "50px auto", width: 300, height: 300}}>
                    <QRCode style={{display: sessionKey===""?"none":"block"}} level={"H"} size={300} value={sessionKey}/>
                </div>
                <p className="mb-2" style={{display: errMsg!==""?"block":"none", color: "red"}}>{errMsg}</p>
            </Card.Body>
            <Card.Footer style={{display: reason?"block":"none"}}>
                <span style={{display: reason?"block":"none", color: "red"}}>
                    {reason==="forceLogout"?"Someone logged in from other device or browser. Please login again.":reason==="logout"?"Logout Success.":""}
                </span>
            </Card.Footer>
        </Card>
    )
}

export default QRCodeLoginPage