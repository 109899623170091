import { useState, useEffect, useMemo, useRef, useContext } from "react";

import { Table } from "react-bootstrap"

import SymbolSelect from "./component/SymbolSelect";

function BookTable({bids=[], asks=[]})
{
    const TableHeader = useMemo(()=>{
        return (
            <thead style={{fontSize: 14}}>
                <tr>
                    <th style={{textAlign: "center", padding: "6px 4px"}} colSpan={2}>Bid</th>
                    <th style={{textAlign: "center", padding: "6px 4px"}} colSpan={2}>Ask</th>
                </tr>
                <tr>
                    <th style={{textAlign: "center", padding: "4px 4px"}} width="25%">B.Qty</th>
                    <th style={{textAlign: "center", padding: "4px 4px"}} width="25%">Bid</th>
                    <th style={{textAlign: "center", padding: "4px 4px"}} width="25%">Ask</th>
                    <th style={{textAlign: "center", padding: "4px 4px"}} width="25%">A.Qty</th>
                </tr>
            </thead>
        )
    }, [])

    const TableBody = useMemo(()=>{
        const Rows = []

        const BidTotal = bids.reduce((p,c)=>p+c.size, 0)
        const AskTotal = asks.reduce((p,c)=>p+c.size, 0)

        for (let i = 0; i < 10; i++)
        {
            let [bid, ask, bidS, askS] = [null, null, null, null]

            if (bids[i]){
                bid = bids[i].price
                bidS = bids[i].size
            }

            if (asks[i]){
                ask = asks[i].price
                askS = asks[i].size
            }

            const BidRatio = (bidS?bidS/BidTotal:0) * 100
            const AskRatio = (askS?askS/AskTotal:0) * 100

            Rows.push(
                <tr key={i}>
                    <td colSpan={2} className="BarContainer" style={{textAlign: "center"}}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div style={{flex: 1, padding: "4px 4px"}}>{bidS?bidS:<>&nbsp;</>} </div>
                            <div style={{flex: 1, padding: "4px 4px"}}>{bid?bid:<>&nbsp;</>}</div>
                            <div className="BidBar" style={{position: "absolute", top: 0, left: 0, height: "100%", width: BidRatio+"%"}}></div>
                        </div>
                    </td>
                    <td colSpan={2} className="BarContainer" style={{textAlign: "center"}}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div style={{flex: 1, padding: "4px 4px"}}>{ask?ask:<>&nbsp;</>}</div>
                            <div style={{flex: 1, padding: "4px 4px"}}>{askS?askS:<>&nbsp;</>}</div>
                            <div className="AskBar" style={{position: "absolute", top: 0, right: 0, height: "100%", width: AskRatio+"%"}}></div>
                        </div>
                    </td>
                </tr>
            )
        }
        return <tbody style={{fontSize: 14}}>{Rows.map((x)=>x)}</tbody>
    }, [bids, asks])

    return useMemo(()=>{
        return (
            <Table bordered hover>
                {TableHeader}
                {TableBody}
            </Table>
        )
    }, [TableHeader, TableBody])
}

function OrderBook({onClose, context, item})
{
    const MainContext = useContext(context)

    const SendSubscribe = useMemo(()=>{
		return MainContext.SendSubscribe
	}, [MainContext])

    const [symbol, setSymbol] = useState("")
	const [lockSymbol, setLockSymbol] = useState(false)
	const subscribeSymbol = useRef("")

	const symbolObj = useMemo(()=>{ return {state: symbol, setState: setSymbol} }, [symbol])
	const lockSymbolObj = useMemo(()=>{ return {state: lockSymbol, setState: setLockSymbol} }, [lockSymbol])

    useEffect(()=>{
		if (lockSymbol === false && MainContext.GlobalSubscribe.state !== null)
		{
			if (MainContext.GlobalSubscribe.state.symbol){
				setSymbol(MainContext.GlobalSubscribe.state.symbol)
			}
		}
	}, [lockSymbol, MainContext.GlobalSubscribe.state])

	useEffect(()=>{
		subscribeSymbol.current = symbol

		if (symbol !== "") SendSubscribe(symbol)
	}, [symbol, SendSubscribe])

    const FakeData = useMemo(()=>{
        const bids = []
        const asks = []

        if (symbol === "" || !MainContext.QuoteData[symbol]) return { bids, asks }

        bids.push({price: MainContext.QuoteData[symbol].bid, size: MainContext.QuoteData[symbol].bidS})
        asks.push({price: MainContext.QuoteData[symbol].ask, size: MainContext.QuoteData[symbol].askS})

        /*const startPrice = MainContext.QuoteData[symbol].bid
        for (let i = 0; i < 10; i++)
        {
            bids.push({price: (bids.length>0?bids[bids.length-1].price-1:startPrice), size: Math.ceil(Math.random() * 100)})
            asks.push({price: (asks.length>0?asks[asks.length-1].price+1:startPrice+10), size: Math.ceil(Math.random() * 100)})
        }*/

        return { bids, asks }
    }, [MainContext.QuoteData, symbol])

    return useMemo(()=>{
        return (
            <div style={{flex: 1}}>
                <SymbolSelect symbol={symbolObj} lockSymbol={lockSymbolObj} />
                <BookTable bids={FakeData.bids} asks={FakeData.asks}/>
            </div>
        )
    }, [symbolObj, lockSymbolObj, FakeData])
}

export default OrderBook