import EChartsReact from "echarts-for-react";
import { useState, useEffect, useMemo, useRef, useContext } from "react";
import { TimeString } from "../../functions/DateHandle";

import SymbolSelect from "./component/SymbolSelect";

function KlineChart({onClose, context, item})
{
    const MainContext = useContext(context)

    const [GlobalSubscribe, SendSubscribe, QuoteData] = useMemo(()=>{
        return [MainContext.GlobalSubscribe, MainContext.SendSubscribe, MainContext.RealtimeQuoteData]
    }, [MainContext])

    const [symbol, setSymbol] = useState("")
	const [lockSymbol, setLockSymbol] = useState(false)
	const subscribeSymbol = useRef("")

    const chartDataRef = useRef({lastTime: 0, data: []})
    const [chartData, setChartData] = useState([])

	const symbolObj = useMemo(()=>{ return {state: symbol, setState: setSymbol} }, [symbol])
	const lockSymbolObj = useMemo(()=>{ return {state: lockSymbol, setState: setLockSymbol} }, [lockSymbol])

    useEffect(()=>{
		if (lockSymbol === false && GlobalSubscribe.state !== null)
		{
			if (GlobalSubscribe.state.symbol){
				setSymbol(GlobalSubscribe.state.symbol)
			}
		}
	}, [lockSymbol, GlobalSubscribe.state])

    useEffect(()=>{
		subscribeSymbol.current = symbol
        chartDataRef.current = {lastTime: 0, data: []}
        setChartData([])

		SendSubscribe(symbol)
	}, [symbol])

    const CurrQuoteData = useMemo(()=>{
        if (symbol === "") return null
        return QuoteData[symbol]
    }, [QuoteData, symbol])

    useEffect(()=>{
        if (CurrQuoteData)
        {
            const data = chartDataRef.current.data
            const lastItem = data[data.length - 1]

            const price = CurrQuoteData.tradeP!==undefined?CurrQuoteData.tradeP:CurrQuoteData.bid
            const volume = CurrQuoteData.tradeS!==undefined?CurrQuoteData.tradeS:0

            if (price === 0) return

            const currTime = new Date()
            const currTimeBase = currTime - currTime % (60 * 1000)

            if (!lastItem || lastItem.time < currTimeBase)
            {
                data.push({time: currTimeBase, open: price, high: price, low: price, close: price, volume: volume})
            }
            else
            {
                lastItem.close = price
                lastItem.low = Math.min(lastItem.low, price)
                lastItem.high = Math.max(lastItem.high, price)
                lastItem.volume += volume
            }

            if ((currTime - chartDataRef.current.lastTime) > 500)
            {
                chartDataRef.current.lastTime = currTime
                setChartData([...data])
            }
        }
    }, [CurrQuoteData])

    const [option, setOption] = useState({})

    useEffect(()=>{
        setOption({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                }
            },
            xAxis: [
                {
                    data: chartData.map((x)=>{
                        return TimeString("hh:mm", new Date(x.time))
                    })
                }
            ],
            yAxis: [
                {
                    scale: true
                }
            ],
            series: [
                {
                    type: 'candlestick',
                    data: chartData.map((x)=>{
                        return [x.open, x.close, x.low, x.high]
                    })
                }
            ]
        })
    }, [chartData])

    return useMemo(()=>{
        return (
            <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                <SymbolSelect symbol={symbolObj} lockSymbol={lockSymbolObj} />
                <div style={{width: "100%", flex: 1}}>
                    <EChartsReact style={{height: "100%"}} option={option} lazyUpdate notMerge/>
                </div>
            </div>
        )
    }, [symbolObj, lockSymbolObj, option])
}

export default KlineChart