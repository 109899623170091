import { useState, useEffect, useMemo, useContext } from "react"
import { TimeString } from "../../functions/DateHandle";

import SymbolSelect from "./component/SymbolSelect";

import "../../css/DivTable.css"

function MarketTrades({onClose, context, item})
{
    const MainContext = useContext(context)

    const [symbol, setSymbol] = useState("")
	const [lockSymbol, setLockSymbol] = useState(false)

    const symbolObj = useMemo(()=>{ return {state: symbol, setState: setSymbol} }, [symbol])
	const lockSymbolObj = useMemo(()=>{ return {state: lockSymbol, setState: setLockSymbol} }, [lockSymbol])

    useEffect(()=>{
		if (lockSymbol === false && MainContext.GlobalSubscribe.state !== null)
		{
			if (MainContext.GlobalSubscribe.state.symbol){
				setSymbol(MainContext.GlobalSubscribe.state.symbol)
			}
		}
	}, [lockSymbol, MainContext.GlobalSubscribe.state])

    useEffect(()=>{
		// TODO: Send Subscribe
	}, [symbol])

    const MarketTradesData = useMemo(()=>{
        return MainContext.MarketTradesData[symbol]
    }, [MainContext, symbol])

    const [TableHeader] = useMemo(()=>{
        const keys = ["time", "price", "volume"]
        const names = ["Time", "Price", "Volume"]

        return [(
            <thead>
                <tr>
                    { keys.map((x, i)=>{ return <th key={x}>{names[i]}</th> }) }
                </tr>
            </thead>
        )]
    }, [])
    const TableBody = useMemo(()=>{
        if (!MarketTradesData) return <></>

        return (
            <tbody>
                {
                    MarketTradesData.map((x, i)=>{
                        return (
                            <tr key={i}>
                                <td>{TimeString("hh:mm:ss", x.time)}</td>
                                <td style={{textAlign: "right"}}>{x.price}</td>
                                <td style={{textAlign: "right"}}>{x.volume}</td>
                            </tr>
                        )
                    }).reverse()
                }
            </tbody>
        )
    }, [MarketTradesData])

    return useMemo(()=>{
        return (
            <div style={{fontSize: 14, width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
                <SymbolSelect symbol={symbolObj} lockSymbol={lockSymbolObj} />
                <div className="FixHeadTable" style={{flex: 1}}>
                    <table>
                        {TableHeader}
                        {TableBody}
                    </table>
                </div>
            </div>
        )
    }, [symbolObj, lockSymbolObj, TableHeader, TableBody])
}

export default MarketTrades