import { useMemo, useContext } from "react"

import "../../css/DivTable.css"
import { TimeString } from "../../functions/DateHandle"

function GeneralMessage({onClose, context, item})
{
    const GeneralMessageData = useContext(context).GeneralMessageData

    const [TableHeader, TableKeys] = useMemo(()=>{
        const keys = ["time", "message"]
        const names = { time: "Time", message: "Message" }

        return [(
            <thead>
                <tr>
                    { keys.map((x)=>{ return <th key={x} style={{width: (x==="time"?"20%":"80%")}}>{names[x]}</th> }) }
                </tr>
            </thead>
        ), keys]
    }, [])

    const TableBody = useMemo(()=>{
        return (
            <tbody>
                {
                    GeneralMessageData.map((x, i)=>{
                        return (
                            <tr key={i}>
                                {
                                    TableKeys.map((y)=>{
                                        if (y === "time") return <td key={y}>{TimeString("hh:mm:ss", x[y])}</td>
                                        return <td key={y} style={{textAlign: "left"}}>{x[y]}</td>
                                    })
                                }
                            </tr>
                        )
                    }).reverse()
                }
            </tbody>
        )
    }, [TableKeys, GeneralMessageData])

    return useMemo(()=>{
        return (
            <div style={{fontSize: 14, width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
                <div className="FixHeadTable" style={{flex: 1}}>
                    <table>
                        { TableHeader }
                        { TableBody }
                    </table>
                </div>
            </div>
        )
    }, [TableHeader, TableBody])
}

export default GeneralMessage