import React, { useState, useEffect, useMemo, useRef, useContext, useCallback } from "react"

import { GetTimeEpoch, TimeString } from "../../functions/DateHandle";
import RegexHandle from "../../functions/RegexHandle";

import {ExpireType2ID, ID2ExpireType, OrderType2ID, ID2OrderType, OrderType2ExpireType, OrderSide} from "../../functions/API"

import { Button, InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form"
import SymbolSelect from "./component/SymbolSelect";

function BaseInputs({symbol, lockSymbol, orderType, expireType, days})
{
	const SetOrderType = useMemo(()=>{
		const ChangeOrderType = (type) => {
			orderType.setState(type)
			if (Object.keys(OrderType2ExpireType).indexOf(type) > -1)
			{
				expireType.setState(OrderType2ExpireType[type][0])
				days.setState(1)
			}
		}

		return (
			<InputGroup className='mb-2' >
				<InputGroup.Text style={{fontSize: 14, minWidth: 105, padding: "4px 4px"}}>Order Type</InputGroup.Text>
				<Form.Select style={{fontSize: 14, padding: "4px 4px"}}
					value={orderType.state}
					onChange={(e)=>{
						const value = parseInt(e.target.value)
						orderType.setState(value)
						ChangeOrderType(value)
					}}
				>
					{ Object.keys(ID2OrderType).map((x)=>{ return <option key={x} value={x}>{ID2OrderType[x]}</option> }) }
				</Form.Select>
			</InputGroup>
		)
	}, [orderType, expireType, days])

	return useMemo(()=>{

		return (
			<div>
				<SymbolSelect symbol={symbol} lockSymbol={lockSymbol} minWidth={105} padding={"4px 4px"}/>
				{SetOrderType}
			</div>
		)
	}, [lockSymbol, symbol, SetOrderType])
}

function ConditionInput({quote, triggerPrice, price, lot, side, ocoBuyPrice, ocoBuyLot, ocoSellPrice, ocoSellLot, orderType, expireType, days})
{
	const [InputTriggerPrice, setInputTriggerPrice] = useState(null)
	const [InputPrice, setInputPrice] = useState(null)
	const [InputLot, setInputLot] = useState(null)
	const [InputDate, setInputDate] = useState(null)
	const [InputOCOBuyPrice, setInputOCOBuyPrice] = useState(null)
	const [InputOCOBuyLot, setInputOCOBuyLot] = useState(null)
	const [InputOCOSellPrice, setInputOCOSellPrice] = useState(null)
	const [InputOCOSellLot, setInputOCOSellLot] = useState(null)

	// Options
	const ExpireTypeOptions = useMemo(()=>{
		const options = OrderType2ExpireType[orderType.state]??[]

		return <>{options.map((x)=>{return <option key={x} value={x}>{ID2ExpireType[x]}</option>})}</>
	}, [orderType])

	// Controller
	const BtnStyle = useMemo(()=>{return {flex: 1, fontSize: 14, padding: "4px 4px"}}, [])
	const TriggerPriceControl = useMemo(()=>{
		return (
			<div key="TriggerPriceControl" style={{display: "flex"}}>
				<div style={{fontSize: 14, minWidth: 105, padding: "4px 4px"}}>
					Trigger&nbsp;Price
				</div>
				<InputGroup className='mb-2'>
					<Form.Control ref={(r)=>{setInputTriggerPrice(r)}} style={{fontSize: 14, padding: "4px 8px", textAlign: "right"}}
						onBlur={(e)=>{
							if (e.target.value !== "") triggerPrice.setState(parseFloat(e.target.value))
						}}
						onChange={(e)=>{
							e.target.value = RegexHandle.ReplaceFloat(e.target.value, triggerPrice.state)
							const value = parseFloat(e.target.value)
							if (isNaN(value) || value.toString() !== e.target.value)
								triggerPrice.setState(e.target.value)
							else
								triggerPrice.setState(value)
						}}
					/>
				</InputGroup>
			</div>
		)
	}, [triggerPrice])
	const PriceControl = useMemo(()=>{
		const disabled = orderType.state === OrderType2ID.Market

		return (
			<div key="PriceControl" style={{display: "flex"}}>
				<div style={{fontSize: 14, minWidth: 105, padding: "4px 4px"}}>
					Price
				</div>
				<InputGroup className='mb-2'>
					<Button disabled={disabled} variant="success" style={{fontSize: 14, width: 48, padding: "4px 0px"}}
						onClick={()=>{
							if (quote) price.setState(quote.bid)
						}}
					>
						Bid
					</Button>
					<Form.Control disabled={disabled} ref={(r)=>{setInputPrice(r)}} style={{fontSize: 14, padding: "4px 8px", textAlign: "right"}}
						onBlur={(e)=>{
							if (e.target.value !== "") price.setState(parseFloat(e.target.value))
						}}
						onChange={(e)=>{
							e.target.value = RegexHandle.ReplaceFloat(e.target.value, price.state)
							const value = parseFloat(e.target.value)
							if (isNaN(value) || value.toString() !== e.target.value)
								price.setState(e.target.value)
							else
								price.setState(value)
						}}
					/>
					<Button disabled={disabled} variant="danger" style={{fontSize: 14, width: 48, padding: "4px 0px"}}
						onClick={()=>{
							if (quote) price.setState(quote.ask)
						}}
					>
						Ask
					</Button>
				</InputGroup>
			</div>
		)
	}, [quote, orderType, side, price])
	const LotControl = useMemo(()=>{
		return (
			<div key="LotControl" style={{display: "flex"}}>
				<div style={{fontSize: 14, minWidth: 105, padding: "4px 4px"}}>
					Lot
				</div>
				<InputGroup className='mb-2'>
					<Button variant="success" style={{fontSize: 14, width: 48, padding: "4px 0px"}}
						onClick={()=>{
							lot.setState((lastValue)=>{
								if (typeof(lastValue) !== "number") return 0
								return lastValue>0?lastValue-1:0
							})
						}}
					>
						-
					</Button>
					<Form.Control ref={(r)=>{setInputLot(r)}} style={{fontSize: 14, padding: "4px 8px", textAlign: "right"}}
						onChange={(e)=>{
							e.target.value = RegexHandle.ReplaceInterger(e.target.value)
							const value = parseInt(e.target.value)
							lot.setState(isNaN(value)?e.target.value:value)
						}}
					/>
					<Button variant="danger" style={{fontSize: 14, width: 48, padding: "4px 0px"}}
						onClick={()=>{
							lot.setState((lastValue)=>{
								if (typeof(lastValue) !== "number") return 1
								return lastValue+1
							})
						}}
					>
						+
					</Button>
				</InputGroup>
			</div>
		)
	}, [lot])
	const ExpireTypeControl = useMemo(()=>{
		return (
			<InputGroup key="ExpireTypeControl" className="mb-2">
				<InputGroup.Text style={{fontSize: 14, minWidth: 105, padding: "4px 4px"}}>Expire Type</InputGroup.Text>
				<Form.Select style={{fontSize: 14, padding: "4px 4px"}} value={expireType.state}
					onChange={(e)=>{
						const value = parseInt(e.target.value)
						if (value !== ExpireType2ID.Days)
						{
							days.setState(1)
						}
						expireType.setState(parseInt(e.target.value))
					}}
				>
					{ExpireTypeOptions}
				</Form.Select>
			</InputGroup>
		)
	}, [ExpireTypeOptions, expireType, days])
	const DateControl = useMemo(()=>{
		const disableDate = expireType.state !== ExpireType2ID.Days

		return (
			<div key="DateControl" style={{display: "flex"}}>
				<div style={{fontSize: 14, minWidth: 105, padding: "4px 4px"}}>Days</div>
				<Form.Control disabled={disableDate} ref={(r)=>{setInputDate(r)}} style={{fontSize: 14, padding: "4px 8px", textAlign: "right"}} type="text"
					onChange={(e)=>{
						e.target.value = RegexHandle.ReplaceInterger(e.target.value)
						const value = parseInt(e.target.value)
						days.setState(isNaN(value)?e.target.value:value)
					}}
				/>
			</div>
		)
	}, [expireType, days])
	const SideControl = useMemo(()=>{
		return (
			<div key="SideControl" className="mb-2" style={{display: "flex", gap: 8}}>
				<Button variant={side.state!==OrderSide.Buy?"secondary":"success"} onClick={()=>{side.setState(OrderSide.Buy)}} style={BtnStyle}>BUY</Button>
				<Button variant={side.state!==OrderSide.Sell?"secondary":"danger"}  onClick={()=>{side.setState(OrderSide.Sell)}} style={BtnStyle}>SELL</Button>
			</div>
		)
	}, [BtnStyle, side])
	const OCOBuyPriceControl = useMemo(()=>{
		return (
			<div key="OCOBuyPriceControl" style={{display: "flex"}}>
				<div style={{fontSize: 14, minWidth: 105, padding: "4px 4px"}}>
					Buy&nbsp;Price
				</div>
				<InputGroup className='mb-2'>
					<Form.Control ref={(r)=>{setInputOCOBuyPrice(r)}} style={{fontSize: 14, padding: "4px 8px", textAlign: "right"}}
						onBlur={(e)=>{
							if (e.target.value !== "") ocoBuyPrice.setState(parseFloat(e.target.value))
						}}
						onChange={(e)=>{
							e.target.value = RegexHandle.ReplaceFloat(e.target.value, ocoBuyPrice.state)
							const value = parseFloat(e.target.value)
							if (isNaN(value) || value.toString() !== e.target.value)
								ocoBuyPrice.setState(e.target.value)
							else
								ocoBuyPrice.setState(value)
						}}
					/>
				</InputGroup>
			</div>
		)
	}, [ocoBuyPrice])
	const OCOBuyLotControl = useMemo(()=>{
		return (
			<div key="OCOBuyLotControl" style={{display: "flex"}}>
				<div style={{fontSize: 14, minWidth: 105, padding: "4px 4px"}}>
					Buy Lot
				</div>
				<InputGroup className='mb-2'>
					<Button variant="success" style={{fontSize: 14, width: 48, padding: "4px 0px"}}
						onClick={()=>{
							ocoBuyLot.setState((lastValue)=>{
								if (typeof(lastValue) !== "number") return 0
								return lastValue>0?lastValue-1:0
							})
						}}
					>
						-
					</Button>
					<Form.Control ref={(r)=>{setInputOCOBuyLot(r)}} style={{fontSize: 14, padding: "4px 8px", textAlign: "right"}}
						onChange={(e)=>{
							e.target.value = RegexHandle.ReplaceInterger(e.target.value)
							const value = parseInt(e.target.value)
							ocoBuyLot.setState(isNaN(value)?e.target.value:value)
						}}
					/>
					<Button variant="danger" style={{fontSize: 14, width: 48, padding: "4px 0px"}}
						onClick={()=>{
							ocoBuyLot.setState((lastValue)=>{
								if (typeof(lastValue) !== "number") return 1
								return lastValue+1
							})
						}}
					>
						+
					</Button>
				</InputGroup>
			</div>
		)
	}, [ocoBuyLot])
	const OCOSellPriceControl = useMemo(()=>{
		return (
			<div key="OCOSellPriceControl" style={{display: "flex"}}>
				<div style={{fontSize: 14, minWidth: 105, padding: "4px 4px"}}>
					Sell&nbsp;Price
				</div>
				<InputGroup className='mb-2'>
					<Form.Control ref={(r)=>{setInputOCOSellPrice(r)}} style={{fontSize: 14, padding: "4px 8px", textAlign: "right"}}
						onBlur={(e)=>{
							if (e.target.value !== "") ocoSellPrice.setState(parseFloat(e.target.value))
						}}
						onChange={(e)=>{
							e.target.value = RegexHandle.ReplaceFloat(e.target.value, ocoSellPrice.state)
							const value = parseFloat(e.target.value)
							if (isNaN(value) || value.toString() !== e.target.value)
								ocoSellPrice.setState(e.target.value)
							else
								ocoSellPrice.setState(value)
						}}
					/>
				</InputGroup>
			</div>
		)
	}, [ocoSellPrice])
	const OCOSellLotControl = useMemo(()=>{
		return (
			<div key="OCOSellLotControl" style={{display: "flex"}}>
				<div style={{fontSize: 14, minWidth: 105, padding: "4px 4px"}}>
					Sell Lot
				</div>
				<InputGroup className='mb-2'>
					<Button variant="success" style={{fontSize: 14, width: 48, padding: "4px 0px"}}
						onClick={()=>{
							ocoSellLot.setState((lastValue)=>{
								if (typeof(lastValue) !== "number") return 0
								return lastValue>0?lastValue-1:0
							})
						}}
					>
						-
					</Button>
					<Form.Control ref={(r)=>{setInputOCOSellLot(r)}} style={{fontSize: 14, padding: "4px 8px", textAlign: "right"}}
						onChange={(e)=>{
							e.target.value = RegexHandle.ReplaceInterger(e.target.value)
							const value = parseInt(e.target.value)
							ocoSellLot.setState(isNaN(value)?e.target.value:value)
						}}
					/>
					<Button variant="danger" style={{fontSize: 14, width: 48, padding: "4px 0px"}}
						onClick={()=>{
							ocoSellLot.setState((lastValue)=>{
								if (typeof(lastValue) !== "number") return 1
								return lastValue+1
							})
						}}
					>
						+
					</Button>
				</InputGroup>
			</div>
		)
	}, [ocoSellLot])

	useEffect(()=>{
		if (InputTriggerPrice) InputTriggerPrice.value = triggerPrice.state
	}, [triggerPrice, InputTriggerPrice])
	useEffect(()=>{
		if (InputPrice) InputPrice.value = price.state
	}, [price, InputPrice])
	useEffect(()=>{
		if (InputLot) InputLot.value = lot.state
	}, [lot, InputLot])
	useEffect(()=>{
		if (InputDate) InputDate.value = days.state
	}, [days, InputDate])
	useEffect(()=>{
		if (InputOCOBuyPrice) InputOCOBuyPrice.value = ocoBuyPrice.state
	}, [ocoBuyPrice, InputOCOBuyPrice])
	useEffect(()=>{
		if (InputOCOBuyLot) InputOCOBuyLot.value = ocoBuyLot.state
	}, [ocoBuyLot, InputOCOBuyLot])
	useEffect(()=>{
		if (InputOCOSellPrice) InputOCOSellPrice.value = ocoSellPrice.state
	}, [ocoSellPrice, InputOCOSellPrice])
	useEffect(()=>{
		if (InputOCOSellLot) InputOCOSellLot.value = ocoSellLot.state
	}, [ocoSellLot, InputOCOSellLot])

	return useMemo(()=>{
		const DisplayComponents = []

		if (orderType.state === OrderType2ID.Limit)
		{
			DisplayComponents.push(SideControl)
			DisplayComponents.push(PriceControl)
			DisplayComponents.push(LotControl)
			DisplayComponents.push(ExpireTypeControl)
			DisplayComponents.push(DateControl)
		}
		else if (orderType.state === OrderType2ID.Market)
		{
			DisplayComponents.push(SideControl)
			DisplayComponents.push(PriceControl)
			DisplayComponents.push(LotControl)
			DisplayComponents.push(ExpireTypeControl)
		}
		else if (orderType.state === OrderType2ID.StopLimit || orderType.state === OrderType2ID.UpTrigger || orderType.state === OrderType2ID.DownTrigger)
		{
			DisplayComponents.push(SideControl)
			DisplayComponents.push(TriggerPriceControl)
			DisplayComponents.push(PriceControl)
			DisplayComponents.push(LotControl)
			DisplayComponents.push(ExpireTypeControl)
			DisplayComponents.push(DateControl)
		}
		else if (orderType.state === OrderType2ID.OCO)
		{
			DisplayComponents.push(OCOBuyPriceControl)
			DisplayComponents.push(OCOBuyLotControl)
			DisplayComponents.push(OCOSellPriceControl)
			DisplayComponents.push(OCOSellLotControl)
			DisplayComponents.push(ExpireTypeControl)
			DisplayComponents.push(DateControl)
		}

		return (
			<div style={{flex: 1}}>
				{ DisplayComponents.map((x)=>{ return x }) }
			</div>
		)
	}, [
		orderType,
		TriggerPriceControl, PriceControl, LotControl,
		OCOBuyPriceControl, OCOBuyLotControl, OCOSellPriceControl, OCOSellLotControl,
		ExpireTypeControl, DateControl, SideControl
	])
}

function SendOrder({ws, SeriesDefinition, symbol, orderType, side, price, lot, expireType, days})
{
	const NormalOrder = useCallback((symbol, price, lot, side, expireType, orderType, days)=>{
		const { obid, decimal } = (SeriesDefinition[symbol.state]??{obid:0})
		if (obid > 0){
			const Order = {obid}

			Order.orderType = orderType.state
			Order.side = side.state
			Order.price = Math.round(price.state * decimal)
			Order.volume = lot.state
			Order.expireType = expireType.state
			if (Order.expireType === ExpireType2ID.Days)
				Order.days = days.state

			ws.send(JSON.stringify({e:"Trading", subE: "Order", order: Order}))
		}
	}, [ws, SeriesDefinition])

	const BtnStyle = useMemo(()=>{return {flex: 1, fontSize: 14, padding: "4px 0px"}}, [])
	const [variant, setVariant] = useState("primary")
	const OnClick = useMemo(()=>{
		const BaseFunc = (func)=>{
			setVariant("danger")
			func()
			setTimeout(()=>{
				setVariant("primary")
			}, 500)
		}
		if (orderType.state === OrderType2ID.Limit || orderType.state === OrderType2ID.Market)
		{
			return ()=>{
				BaseFunc(()=>{
					NormalOrder(symbol, price, lot, side, expireType, orderType, days)
				})
			}
		}
	}, [NormalOrder, orderType, symbol, side, price, lot, expireType, days])

	const SendBtn = useMemo(()=>{
		const NotAvaliable = orderType.state !== OrderType2ID.Limit && orderType.state !== OrderType2ID.Market

		return (
			<div key="SendBtn" style={{display: "flex"}}>
				<Button variant={NotAvaliable?"danger":variant} style={BtnStyle} disabled={variant==="danger"||NotAvaliable} onClick={OnClick}>
					{NotAvaliable?"Not Avaliable":variant==="primary"?"SEND":"SENDING..."}
				</Button>
			</div>
		)
	}, [BtnStyle, orderType, variant, OnClick])

	return useMemo(()=>{
		const DisplayComponents = []

		if (orderType.state === OrderType2ID.Limit || orderType.state === OrderType2ID.Market)
		{
			DisplayComponents.push(SendBtn)
		}

		if (orderType.state === OrderType2ID.StopLimit || orderType.state === OrderType2ID.UpTrigger || orderType.state === OrderType2ID.DownTrigger || orderType.state === OrderType2ID.OCO)
		{
			DisplayComponents.push(SendBtn)
		}

		return (
			<div>
				{ DisplayComponents.map((x)=>{ return x }) }
			</div>
		)
	}, [orderType, SendBtn])
}

function OrderEntry({onClose, context, item})
{
	const MainContext = useContext(context)

	const [GlobalSubscribe, SendSubscribe, QuoteData] = useMemo(()=>{
		return [MainContext.GlobalSubscribe, MainContext.SendSubscribe, MainContext.QuoteData]
	}, [MainContext])

	//Inputs
	const [symbol, setSymbol] = useState("")
	const [lockSymbol, setLockSymbol] = useState(false)
	const keepPrice = useRef(false)
	const subscribeSymbol = useRef("")

	const [orderType, setOrderType] = useState(OrderType2ID.Limit)
	const [triggerPrice, setTriggerPrice] = useState(0)
	const [price, setPrice] = useState(0)
	const [lot, setLot] = useState(1)
	const [side, setSide] = useState(OrderSide.Buy)
	const [ocoBuyPrice, setOCOBuyPrice] = useState(0)
	const [ocoBuyLot, setOCOBuyLot] = useState(1)
	const [ocoSellPrice, setOCOSellPrice] = useState(0)
	const [ocoSellLot, setOCOSellLot] = useState(1)
	const [expireType, setExpireType] = useState(ExpireType2ID.Today)
	const [days, setDays] = useState(1)

	const symbolObj = useMemo(()=>{ return {state: symbol, setState: setSymbol} }, [symbol])
	const lockSymbolObj = useMemo(()=>{ return {state: lockSymbol, setState: setLockSymbol} }, [lockSymbol])
	const orderTypeObj = useMemo(()=>{ return {state: orderType, setState: setOrderType} }, [orderType])
	const triggerPriceObj = useMemo(()=>{ return {state: triggerPrice, setState: setTriggerPrice} }, [triggerPrice])
	const priceObj = useMemo(()=>{ return {state: price, setState: setPrice} }, [price])
	const lotObj = useMemo(()=>{ return {state: lot, setState: setLot} }, [lot])
	const sideObj = useMemo(()=>{ return {state: side, setState: setSide} }, [side])
	const ocoBuyPriceObj = useMemo(()=>{ return {state: ocoBuyPrice, setState: setOCOBuyPrice} }, [ocoBuyPrice])
	const ocoBuyLotObj = useMemo(()=>{ return {state: ocoBuyLot, setState: setOCOBuyLot} }, [ocoBuyLot])
	const ocoSellPriceObj = useMemo(()=>{ return {state: ocoSellPrice, setState: setOCOSellPrice} }, [ocoSellPrice])
	const ocoSellLotObj = useMemo(()=>{ return {state: ocoSellLot, setState: setOCOSellLot} }, [ocoSellLot])
	const expireTypeObj = useMemo(()=>{ return {state: expireType, setState: setExpireType} }, [expireType])
	const daysObj = useMemo(()=>{ return {state: days, setState: setDays} }, [days])

	const cleanUP = useCallback(()=>{
		setOrderType(OrderType2ID.Limit)
		setTriggerPrice(0)
		setLot(1)
		setSide(OrderSide.Buy)
		setOCOBuyPrice(0)
		setOCOBuyLot(1)
		setOCOSellPrice(0)
		setOCOSellLot(1)
		setExpireType(ExpireType2ID.Today)
		setDays(1)
	}, [])

	useEffect(()=>{
		if (lockSymbol === false && GlobalSubscribe.state !== null)
		{
			if (GlobalSubscribe.state.symbol){
				setSymbol((currSymbol)=>{
					if (GlobalSubscribe.state.price && (new Date()).getTime() - GlobalSubscribe.state.time < 100)
					{
						if (GlobalSubscribe.state.symbol !== currSymbol)
							keepPrice.current = true
						setPrice(GlobalSubscribe.state.price)

						cleanUP()
					}

					return GlobalSubscribe.state.symbol
				})
			}
		}
	}, [cleanUP, lockSymbol, GlobalSubscribe.state])

	useEffect(()=>{
		subscribeSymbol.current = symbol

		if (keepPrice.current === false) setPrice(0)
		else keepPrice.current = false

		cleanUP()

		SendSubscribe(symbol)
	}, [cleanUP, symbol])

	const CurrentSymbolQuote = useMemo(()=>{
		return QuoteData[symbol]
	}, [QuoteData, symbol])
	useEffect(()=>{
		if (CurrentSymbolQuote)
		{
			const {bid, ask} = CurrentSymbolQuote
			if (price === 0 || orderType === OrderType2ID.Market) setPrice(side===OrderSide.Buy?ask:bid)
			if (triggerPrice === 0) setTriggerPrice(side===OrderSide.Buy?bid:ask)
			if (ocoBuyPrice === 0) setOCOBuyPrice(bid)
			if (ocoSellPrice === 0) setOCOSellPrice(ask)
		}
	}, [CurrentSymbolQuote, orderType, side, price, triggerPrice, ocoBuyPrice, ocoSellPrice])

	return useMemo(()=>{
		return (
			<div style={{flex: 1, display: "flex", flexDirection: "column"}}>
				<BaseInputs
					symbol={symbolObj}
					lockSymbol={lockSymbolObj}
					orderType={orderTypeObj}
					expireType={expireTypeObj}
					days={daysObj}
				/>
				<ConditionInput
					quote={CurrentSymbolQuote}
					triggerPrice={triggerPriceObj}
					price={priceObj}
					lot={lotObj}
					side={sideObj}
					ocoBuyPrice={ocoBuyPriceObj}
					ocoBuyLot={ocoBuyLotObj}
					ocoSellPrice={ocoSellPriceObj}
					ocoSellLot={ocoSellLotObj}
					expireType={expireTypeObj}
					days={daysObj}

					orderType={orderTypeObj}
				/>
				<SendOrder
					ws={MainContext.ws}
					SeriesDefinition={MainContext.SeriesDefinition}
					symbol={symbolObj}
					orderType={orderTypeObj}

					triggerPrice={triggerPriceObj}
					price={priceObj}
					lot={lotObj}
					side={sideObj}
					ocoBuyPrice={ocoBuyPriceObj}
					ocoBuyLot={ocoBuyLotObj}
					ocoSellPrice={ocoSellPriceObj}
					ocoSellLot={ocoSellLotObj}
					expireType={expireTypeObj}
					days={daysObj}
				/>
			</div>
		)
	}, [
		MainContext.ws, MainContext.SeriesDefinition, CurrentSymbolQuote,
		symbolObj, lockSymbolObj, orderTypeObj,
		triggerPriceObj, priceObj, lotObj, sideObj,
		ocoBuyPriceObj, ocoBuyLotObj, ocoSellPriceObj, ocoSellLotObj,
		expireTypeObj, daysObj
	])
}

export default OrderEntry