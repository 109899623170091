import { useState, useEffect, useMemo, useCallback } from 'react'

import { Container } from 'react-bootstrap'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

function ShowTime(){
    const [time, setTime] = useState("")
    const [timer, setTimer] = useState(null)

    useEffect(()=>{
        if (timer === null)
            setTimer(
                setInterval(()=>{
                    const currentTime = new Date()
                    const [h,m,s] = [("0"+currentTime.getHours()).slice(-2), ("0"+currentTime.getMinutes()).slice(-2), ("0"+currentTime.getSeconds()).slice(-2)]
                    setTime(h+":"+m+":"+s)
                }, 50)
            )
    }, [timer])

    return useMemo(()=>{
        return (
            <div style={{flex:3, textAlign: "center"}}>
                <span style={{color: "white", padding: "0 1em"}}>{time}</span>
            </div>
        )
    }, [time])
}

function AddComponentList({addLayout}){
    return useMemo(()=>{
        let ShowableItem = []

        return (
            <>
                {
                    ShowableItem.map((x)=>{ return x })
                }
            </>
        )
    }, [])
}

function TopNav({ws, session, setLayout}){

    const onLogout = useCallback(()=>{
        delete(localStorage.userID)
        window.location = "/login"
        if (ws && session)
            ws.send(JSON.stringify({e: "logout", sessionID: session}))
    }, [ws, session])

    const addLayout = useCallback((component, data_grid)=>{
        setLayout((last)=>{
            let newArr = last.slice()
            let maxKey = 0
            let maxY = 0
            last.forEach((x)=>{
                if (maxY < x["data-grid"].y + x["data-grid"].h)
                    maxY = x["data-grid"].y + x["data-grid"].h
                if (maxKey < x["key"])
                    maxKey = x["key"]
            })

            data_grid.y = maxY

            newArr.push({"key": maxKey+1, "data-grid": data_grid, "component": component})
			return newArr
        })
    }, [setLayout])

    return useMemo(()=>{
        return (
            <Navbar bg="dark" variant="dark" style={{padding: 0}}>
                <Container fluid style={{display: "flex", fontSize: 16}}>
                    <Nav style={{flex: 1}}>
                        <NavDropdown title="Components">
                            <AddComponentList addLayout={addLayout} />
                        </NavDropdown>
                    </Nav>
                    <ShowTime/>
                    <Nav style={{flex: 1, justifyContent: "right"}}>
                        <Nav.Link onClick={onLogout}>Logout</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        )
    }, [onLogout, addLayout])
}

export default TopNav