import React, { useMemo } from "react";

import "../css/RightClickMenu.css"

function RightClickMenu({
    MenuName = "Menu",
    position = {left: 0, top: 0},
    event,
    touching={current: false},
    ButtonNames = ["Test"],
    ButtonOnClick = [()=>{console.log("Test Clicked.")}],
    setShowMenu = ()=>{}
})
{
    const RealButtonOnClick = useMemo(()=>{
        return ButtonOnClick.map((x)=>{
            return (e)=>{
                if (touching.current) return
                x(e)
                setShowMenu(null)
            }
        })
    }, [ButtonOnClick, touching, setShowMenu])

    const eventPosition = useMemo(()=>{
        if (!event) return {}

        const x = event.touches?(event.touches[0].clientX + event.touches[1].clientX)/2:event.clientX
		const y = event.touches?(event.touches[0].clientY + event.touches[1].clientY)/2:event.clientY

		const realX = Math.min(event.view.innerWidth-210, x)
        const realY = Math.min(event.view.innerHeight-150, y)

        return {left: realX, top: realY}
    }, [event])

    return useMemo(()=>{
        return (
            <div className="RightClickMenu" style={{
                left: eventPosition.left??position.left, top: eventPosition.top??position.top
            }} data-menuname={MenuName} onClick={(e)=>{ e.stopPropagation(); }}>
                <hr/>
                <ul>
                    {
                        ButtonNames.map((x, i)=>{
                            return (
                                <li key={i} onClick={RealButtonOnClick[i]}>{x}</li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }, [MenuName, position, eventPosition, ButtonNames, RealButtonOnClick])
}

export default RightClickMenu