import { useEffect } from 'react'
import { useState, useMemo, useRef, useContext, useCallback } from 'react'

import { Button, Table } from 'react-bootstrap'
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"

import RightClickMenu from '../../components/RightClickMenu'
import { ParseBigNumber } from '../../functions/NumberProcess'

function MarketPrice({onClose, context, item})
{
	const MainContext = useContext(context)

	const touching = useRef(false)
	const [setShowMenu, SeriesDefinition, GlobalSubscribe, SendSubscribe] = useMemo(()=>{
		return [MainContext.setShowMenu, MainContext.SeriesDefinition, MainContext.GlobalSubscribe, MainContext.SendSubscribe]
	}, [MainContext])
	const ExistSymbol = useMemo(()=>{return Object.keys(SeriesDefinition)}, [SeriesDefinition])

	const [subscribeSymbols, setSubscribeSymbols] = useState(JSON.parse(localStorage.subscribeSymbols??JSON.stringify(["HSIN2"])))
	const [showAddSymbol, setShowAddSymbol] = useState(false)
	const [errAddSymbol, setErrAddSymbol] = useState("")

	useEffect(()=>{
		if (ExistSymbol.length > 0)
		{
			setSubscribeSymbols((last)=>{
				return last.filter((x)=>{
					return ExistSymbol.indexOf(x) !== -1
				})
			})
		}
	}, [ExistSymbol])

	useEffect(()=>{
		localStorage.subscribeSymbols = JSON.stringify(subscribeSymbols)
	}, [subscribeSymbols])

	const onShowMenu = useCallback((e)=>{
		setShowMenu(
			<RightClickMenu MenuName='MarketPrice Menu' setShowMenu={setShowMenu} event={e}
				touching={touching}
				ButtonNames={["Add Symbol", "Clear"]}
				ButtonOnClick={[
					()=>{ setShowAddSymbol(true) },
					()=>{setSubscribeSymbols([])}
				]}
			/>
		)
	}, [setShowMenu])

	const AddSymbolModal = useMemo(()=>{
		return (
			<Modal show={showAddSymbol} onHide={()=>{
				setShowAddSymbol(false)
				setErrAddSymbol("")
			}}>
				<Modal.Header closeButton>
					<Modal.Title>Add Symbol</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form
						onSubmit={(e)=>{
							e.preventDefault();
							e.stopPropagation();
							const formdata = new FormData(e.target)

							const symbol = formdata.get("symbol")
							if (ExistSymbol.indexOf(symbol) !== -1)
							{
								setSubscribeSymbols((last)=>[...last, symbol])
								setShowAddSymbol(false)
								setErrAddSymbol("")
							}
							else
							{
								setErrAddSymbol("Symbol is Not Exist")
							}
						}}
					>
						<Form.Group style={{marginBottom: 8}}>
							<Form.Label>Symbol</Form.Label>
							<Form.Control name="symbol"/>
							<Form.Control.Feedback type="invalid">{errAddSymbol}</Form.Control.Feedback>
						</Form.Group>
						<Button type="submit">Add</Button>
					</Form>
				</Modal.Body>
			</Modal>
		)
	}, [ExistSymbol, showAddSymbol, errAddSymbol])

	const TableHeader = useMemo(()=>{
		const headers = ["Symbol", "BQty", "Bid", "Ask", "AQty", "Last/EP", "LQty", "Chg", "Chg(%)", "Volume", "High", "Low"]
		return (
			<tr>
				{
					headers.map((x)=>{
						if (["Bid", "Ask", "Last/EP", "High", "Low"].indexOf(x) > -1)
							return <th style={{textAlign: "center", padding: "2px 4px"}} key={x}>{x}</th>
						return <th key={x} style={{padding: "2px 4px"}}>{x}</th>
					})
				}
			</tr>
		)
	}, [])

	const TableBodyData = useMemo(()=>{
		const Empty = {bid: 0, bidS: 0, ask: 0, askS: 0}
		return subscribeSymbols.map((symbol)=>{
			if (!MainContext.QuoteData[symbol]) SendSubscribe(symbol)
			const {bid, bidS, ask, askS, last, lastS, open, low, high, volume} = MainContext.QuoteData[symbol]??Empty

			const change = bid-open
			const changeRatio = Math.round(change / open * 10000) /100

			return {symbol: symbol, bidS, bid, ask, askS, last: last, lastS: lastS, change: change, changeRatio: changeRatio, volume: ParseBigNumber(volume), high: high, low: low}
		})
	}, [SendSubscribe, subscribeSymbols, MainContext.QuoteData])

	const TableBody = useMemo(()=>{
		return TableBodyData.map((x, i)=>{
			const ContextMenu = (e)=>{
				setShowMenu(
					<RightClickMenu MenuName={x.symbol} setShowMenu={setShowMenu} event={e}
						touching={touching}
						ButtonNames={["Cap Symbol", "Remove "+x.symbol]}
						ButtonOnClick={[
							()=>{ GlobalSubscribe.setState({symbol: x.symbol}) },
							()=>{ setSubscribeSymbols((old)=>{ const newArr = [...old]; newArr.splice(i, 1); return newArr }) }
						]}
					/>
				)
			}

			return (
				<tr key={i}>
					<td style={{cursor: "pointer", padding: "2px 4px"}}
						onClick={(e)=>{
							e.preventDefault()
							e.stopPropagation()

							ContextMenu(e)
						}}
					>
						{x.symbol}
					</td>
					<td style={{textAlign: "right", padding: "2px 4px"}}>{x.bidS}</td>
					<td style={{textAlign: "center", padding: "2px 4px"}}>{x.bid}</td>
					<td style={{textAlign: "center", padding: "2px 4px"}}>{x.ask}</td>
					<td style={{textAlign: "right", padding: "2px 4px"}}>{x.askS}</td>
					<td style={{textAlign: "center", padding: "2px 4px"}}>{x.last}</td>
					<td style={{textAlign: "right", padding: "2px 4px"}}>{x.lastS}</td>
					<td style={{textAlign: "right", padding: "2px 4px"}}>{x.change}</td>
					<td style={{textAlign: "right", padding: "2px 4px"}}>{x.changeRatio}</td>
					<td style={{textAlign: "right", padding: "2px 4px"}}>{x.volume}</td>
					<td style={{textAlign: "center", padding: "2px 4px"}}>{x.high}</td>
					<td style={{textAlign: "center", padding: "2px 4px"}}>{x.low}</td>
				</tr>
			)
		})
	}, [setShowMenu, TableBodyData, GlobalSubscribe])

	return useMemo(()=>{
		return (
			<div className='react-draggable-cancel' style={{flex: 1}}
				onContextMenu={(e)=>{
					e.preventDefault();
					e.stopPropagation();

					if (e.button === -1) return
					onShowMenu(e)
				}}
				onTouchEnd={()=>{touching.current = false}}
				onTouchStart={(e)=>{
					e.stopPropagation();

					if (e.touches.length === 2)
					{
						touching.current = true
						onShowMenu(e)
					}
				}}
			>
				{AddSymbolModal}
				<Table bordered style={{margin: 0}}>
					<thead style={{fontSize: 14}}>
						{TableHeader}
					</thead>
					<tbody style={{fontSize: 14}}>
						{TableBody}
					</tbody>
				</Table>
			</div>
		)
	}, [onShowMenu, AddSymbolModal, TableHeader, TableBody])
}

export default MarketPrice