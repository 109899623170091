import { useState, useMemo, useContext, useEffect } from "react"
import { Table } from "react-bootstrap"

import { TimeString } from "../../functions/DateHandle"

import SubPageBtnList from "../../components/SubPageBtnList"
import { ID2ExpireType, OrderSide } from "../../functions/API"

function OrderStatus({context})
{
    const OrdersData = useContext(context).OrdersData

    const [TableHeader, TableKeys] = useMemo(()=>{
        const keys = ["time", "oid", "symbol", "side", "price", "volume", "expireType", "condition", "cancel"]
        const TitleName = ["Time", "OID", "Symbol", "Side", "Price", "Volume", "Exp.Type", "Cond.", "Cancel"]

        const TitleStyle = {width: (1/TitleName.length*100)+"%", padding: "2px 2px"}
        return [(
            <thead>
                <tr style={{fontSize: 14}}>
                    {
                        keys.map((x, i)=>{
                            return <th key={x} style={TitleStyle}>{TitleName[i]}</th>
                        })
                    }
                </tr>
            </thead>
        ), keys]
    }, [])

    const TableBody = useMemo(()=>{
        return (
            <tbody>
                {
                    OrdersData.map((x, i)=>{
                        return (
                            <tr key={i} style={{fontSize: 14}}>
                                {
                                    TableKeys.map((y)=>{
                                        if (y === "time") return <td key={y}>{TimeString("hh:mm:ss",x[y])}</td>
                                        if (y === "side")
                                        {
                                            const color = x[y]===OrderSide.Buy?"rgba(25,135,84,.3)":"rgba(220,53,69,.3)"
                                            return <td key={y} style={{textAlign: "center", background: color}}>{x[y]===OrderSide.Buy?"B":"S"}</td>
                                        }
                                        if (y === "expireType")
                                        {
                                            return <td key={y} style={{textAlign: "center"}}>{ID2ExpireType[x[y]]}</td>
                                        }
                                        if (typeof(x[y]) === "number") return <td key={y} style={{textAlign: "right"}}>{x[y]}</td>
                                        return <td key={y}>{x[y]??"N/A"}</td>
                                    })
                                }
                            </tr>
                        )
                    }).reverse()
                }
            </tbody>
        )
    }, [TableKeys, OrdersData])

    return useMemo(()=>{
        return (
            <div className="FixHeadTable" style={{flex: 1}}>
                <table>
                    {TableHeader}
                    {TableBody}
                </table>
            </div>
        )
    }, [TableHeader, TableBody])
}

function TradesStatus({context})
{
    const TradesData = useContext(context).TradesData

    const [TableHeader, TableKeys] = useMemo(()=>{
        const keys = ["time", "symbol", "side", "price", "volume", "pnl"]
        const names = ["Time", "Symbol", "Side", "Price", "Volume", "PnL"]

        return [(
            <thead>
                <tr style={{fontSize: 14}}>
                    { keys.map((x, i)=>{ return <th key={x} style={{fontSize: 14}}>{names[i]}</th> }) }
                </tr>
            </thead>
        ), keys]
    }, [])

    const TableBody = useMemo(()=>{
        return (
            <tbody>
                {
                    TradesData.map((x, i)=>{
                        return (
                            <tr key={i} style={{fontSize: 14}}>
                                {
                                    TableKeys.map((y)=>{
                                        if (y === "time") return <td key={y}>{TimeString("hh:mm:ss",x[y])}</td>
                                        if (y === "side")
                                        {
                                            const color = x[y]===OrderSide.Buy?"rgba(25,135,84,.3)":"rgba(220,53,69,.3)"
                                            return <td key={y} style={{textAlign: "center", backgroundColor: color}}>{x[y]===OrderSide.Buy?"B":"S"}</td>
                                        }
                                        if (y === "pnl")
                                        {
                                            const color = x[y]>0?"rgba(25,135,84,.3)":x[y]<0?"rgba(220,53,69,.3)":x["side"]===OrderSide.Sell?"rgba(255,193,7,.3)":"white"
                                            return <td key={y} style={{textAlign: "right", backgroundColor: color}}>{x[y]}</td>
                                        }
                                        if (typeof(x[y]) === "number") return <td key={y} style={{textAlign: "right"}}>{x[y]}</td>
                                        return <td key={y}>{x[y]}</td>
                                    })
                                }
                            </tr>
                        )
                    }).reverse()
                }
            </tbody>
        )
    }, [TableKeys, TradesData])

    return useMemo(()=>{
        return (
            <div className="FixHeadTable" style={{flex: 1}}>
                <table>
                    {TableHeader}
                    {TableBody}
                </table>
            </div>
        )
    }, [TableHeader, TableBody])
}

function PositionStatus({context})
{
    const MainContext = useContext(context)
    const [QuoteData, PositionData, GlobalSubscribe, SendSubscribe] = useMemo(()=>{
        return [MainContext.QuoteData, MainContext.PositionData, MainContext.GlobalSubscribe, MainContext.SendSubscribe]
    }, [MainContext])

    const [TableHeader, TableKeys] = useMemo(()=>{
        const keys = ["symbol", "pos", "cost", "floating", "pnl", "to"]
        const names = ["Symbol", "Position", "Cost", "Floating", "PnL", "Turnover"]

        return [(
            <thead>
                <tr style={{fontSize: 14}}>
                    { keys.map((x, i)=>{ return <th key={x} className="DivHeaderColumn">{names[i]}</th> })}
                </tr>
            </thead>
        ), keys]
    }, [])

    const TableBody = useMemo(()=>{
        return (
            <tbody>
                {
                    Object.keys(PositionData).sort((a,b)=>{
                        return PositionData[b].to - PositionData[a].to
                    }).sort((a,b)=>{
                        return PositionData[b].pos - PositionData[a].pos
                    }).map((symbol)=>{
                        const x = PositionData[symbol]
                        return (
                            <tr key={symbol} style={{fontSize: 14}}>
                                {
                                    TableKeys.map((y)=>{
                                        if (y === "symbol")
                                        {
                                            return (
                                                <td key={y} style={{cursor: "pointer"}} onClick={(e)=>{
                                                    e.target.style.backgroundColor = "red"
                                                    e.target.style.color = "white"
                                                    GlobalSubscribe.setState({symbol: x[y]})
                                                    setTimeout(()=>{
                                                        e.target.style.backgroundColor = "inherit"
                                                        e.target.style.color = "inherit"
                                                    }, 500)
                                                }}>
                                                    {x[y]}
                                                </td>
                                            )
                                        }
                                        if (y === "floating"){
                                            if (x.pos === 0) return <td key={y} style={{textAlign: "right"}}>0</td>
                                            const currPrice = (QuoteData[symbol]??{bid:0}).bid
                                            const floating = (currPrice - x.cost) * x.pos
                                            if (!QuoteData[symbol]) SendSubscribe(symbol)

                                            const color = floating>0?"rgba(25,135,84,.3)":floating<0?"rgba(220,53,69,.3)":x.pos>0?"rgba(255,193,7,.3)":"white"
                                            return <td key={y} style={{textAlign: "right", backgroundColor: color}}>{Math.round(floating*1000)/1000}</td>
                                        }
                                        if (typeof(x[y]) === "number") return <td key={y} style={{textAlign: "right"}}>{Math.round(x[y]*1000)/1000}</td>
                                        return <td key={y}>{x[y]}</td>
                                    })
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        )
    }, [GlobalSubscribe, SendSubscribe, TableKeys, PositionData, QuoteData])

    return useMemo(()=>{
        return (
            <div className="FixHeadTable" style={{flex: 1}}>
                <table>
                    {TableHeader}
                    {TableBody}
                </table>
            </div>
        )
    }, [TableHeader, TableBody])
}

function AccountStatus({onClose, context, item})
{
    const [page, setPage] = useState("order")

    const DisplayComponent = useMemo(()=>{
        if (page === "order") return OrderStatus
        if (page === "trade") return TradesStatus
        if (page === "position") return PositionStatus
        return ()=>{return <>Not Avaliable</>}
    }, [page])

    return useMemo(()=>{
        return (
            <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
                <SubPageBtnList selectedKey={page} setSelectedKey={setPage} bgColor="#e9ecef" color="black"
                    btnKey={["position","order","trade"]} btnName={["Position", "Order", "Trade"]}
                />
                <DisplayComponent context={context}/>
            </div>
        )
    }, [context, page])
}

export default AccountStatus