import { useMemo } from "react";

import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'

function PopUpStatusModal({connStatus, SeriesDefinition, HistoryEOF})
{
    const DisplayIcon = useMemo(()=>{
        if (connStatus === null || connStatus === false || typeof(connStatus) === "number")
            return (
                <svg style={{width: "100%", height: 200}} width="200px" height="200px" viewBox="0 0 1024 1024">
                    <path fill="#AAA" d="M832.6 191.4c-84.6-84.6-221.5-84.6-306 0l-96.9 96.9 51 51 96.9-96.9c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204l-96.9 96.9 51.1 51.1 96.9-96.9c84.4-84.6 84.4-221.5-.1-306.1zM446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l96.9-96.9-51.1-51.1-96.9 96.9c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l96.9-96.9-51-51-96.8 97zM260.3 209.4a8.03 8.03 0 0 0-11.3 0L209.4 249a8.03 8.03 0 0 0 0 11.3l554.4 554.4c3.1 3.1 8.2 3.1 11.3 0l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3L260.3 209.4z"/>
                </svg>
            )
        if (Object.keys(SeriesDefinition).length === 0 || HistoryEOF === false)
            return (
                <div style={{width: "100%", height: 200, fontSize: "200px", lineHeight: "200px", textAlign: "center", color: "#AAA"}}><FontAwesomeIcon icon={faFileArrowDown}/></div>
            )

    }, [connStatus, SeriesDefinition, HistoryEOF])

    return useMemo(()=>{
        let title = "" 
        if (connStatus===null)
            title = "正在連接伺服器"
        else if (typeof(connStatus)==="number"||connStatus===false)
            title = "失去連線"
        else if (Object.keys(SeriesDefinition).length===0||HistoryEOF===false)
            title = "下載必要資料"
        
        const showCountdown = typeof(connStatus) === "number"||connStatus===false

        return (
            <Modal show={title!==""}>
                <Modal.Header className="bg-danger text-white">
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {DisplayIcon}

                    <div style={{display: showCountdown?"block":"none", textAlign:"center"}}>
                        請檢查你的網絡連接是否正常，我們會持續嘗試重新連線<br/><br/>
                        {connStatus>0?((connStatus/1000)+"秒後重試"):"正在重試..."}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }, [connStatus, SeriesDefinition, HistoryEOF, DisplayIcon])
}

export default PopUpStatusModal