import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'

import { useParams } from 'react-router-dom';
import { Responsive, WidthProvider } from 'react-grid-layout';

import Decimal from 'decimal.js';

import { WebSocketHandle } from '../WebSocket';
import { StatusType, ID2OrderType, OrderSide } from '../functions/API';
import ProfitCalculator from '../functions/ProfitCalculator';
import { Message, Order, Trade } from '../functions/Classes';

import '../react-grid-layout.css'
import '../react-resizable.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import { CloseButton } from 'react-bootstrap';

import TopNav from './MainPage/TopNav';
import MarketPrice from './MainPage/MarketPrice';
import OrderEntry from './MainPage/OrderEntry';
import OptionMaster from './MainPage/OptionMaster';
import OrderBook from './MainPage/Orderbook';
import KlineChart from './MainPage/KlineChart';
import AccountStatus from './MainPage/AccountStatus';
import MarketTrades from './MainPage/MarketTrades';
import GeneralMessage from './MainPage/GeneralMessage';
import PopUpStatusModal from './MainPage/PopUpStatusModal';
import AutoHideToast from '../components/AutoHideToast';

const ResponsiveGridLayout = WidthProvider(Responsive);
const MainContext = React.createContext();

if (window.location.hostname === "localhost") window.DebugMode = true

function loadLayout(id)
{
	if (localStorage["layout"+id]){
		const layout = JSON.parse(localStorage["layout"+id])
		return layout
	}
	
	const defaultLayout = []
	defaultLayout.push({key: 0, pos: {x: 9, y: 80, h: 43, w: 7}, component: "MarketPrice"})
	defaultLayout.push({key: 1, pos: {x: 0, y: 0, h: 80, w: 7}, component: "KlineChart"})
	defaultLayout.push({key: 2, pos: {x: 10, y: 0, h: 80, w: 3}, component: "OrderEntry"})
	defaultLayout.push({key: 3, pos: {x: 7, y: 0, h: 80, w: 3}, component: "OrderBook"})
	defaultLayout.push({key: 4, pos: {x: 13, y: 0, h: 40, w: 3}, component: "MarketTrades"})
	defaultLayout.push({key: 5, pos: {x: 13, y: 40, h: 40, w: 3}, component: "GeneralMessage"})

	if (window.screen.width < 480) // Mobile
		defaultLayout.push({key: 6, pos: {x: 0, y: 80, h: 94, w: 9}, component: "OptionMaster"})
	else //Desktop
		defaultLayout.push({key: 6, pos: {x: 0, y: 80, h: 86, w: 9}, component: "OptionMaster"})

	defaultLayout.push({key: 7, pos: {x: 11, y: 130, h: 43, w: 7}, component: "AccountStatus"})
	
	return defaultLayout
}

const min_3_40 = ["MarketPrice", "GeneralMessage", "MarketTrades", "AccountStatus"]
function PosWithMinSize(item){
	const pos = {}

	// Copy position
	Object.keys(item.pos).forEach((x)=>{ pos[x] = item.pos[x] })

	// Set MinSize
	if (min_3_40.indexOf(item.component) > -1)
	{
		pos.minW = 3
		pos.minH = 40
	}
	else if (item.component === "KlineChart")
	{
		pos.minW = 3
		pos.minH = 60
	}
	else if (item.component === "OrderEntry")
	{
		pos.minW = 3
		pos.minH = 80
	}
	else if (item.component === "OptionMaster")
	{
		pos.minW = 3
		if (window.screen.width < 480)
		{
			pos.minH = 94
			pos.maxH = 94
		}
		else{
			pos.minH = 86
			pos.maxH = 86
		}
	}

	return pos
}

function ShowComponent({onClose, context, item}){
	const [childOnClose, setChildOnClose] = useState(()=>{return ()=>{}})

	const RealOnClose = useCallback(()=>{
		childOnClose()
		onClose()
	}, [onClose, childOnClose])

	const Component = useMemo(()=>{
		if (item.component === "MarketPrice") return MarketPrice
		if (item.component === "OrderEntry") return OrderEntry
		if (item.component === "OptionMaster") return OptionMaster
		if (item.component === "OrderBook") return OrderBook
		if (item.component === "KlineChart") return KlineChart
		if (item.component === "AccountStatus") return AccountStatus
		if (item.component === "MarketTrades") return MarketTrades
		if (item.component === "GeneralMessage") return GeneralMessage

		return ({onClose, context, item})=>{ return <>{item.component}</> }
	}, [item.key])

	return useMemo(()=>{
		/* #0d6efd */
		return (
			<>
				<div className="react-draggable-handle" style={{backgroundColor: "#0d6efd",display: "flex"}}>
					<legend style={{fontSize: 16, color: "white", margin: "2px 6px"}}>{item.component}</legend>
					<div style={{height: "100%", backgroundColor: "#bb2d3b"}}>
						<CloseButton className='react-draggable-cancel' variant="white" style={{fontSize: 16, color: "white", margin: "2px 2px"}}
							onClick={RealOnClose}
						/>
					</div>
				</div>
				<div className="react-draggable-cancel" style={{padding: 10, flex: 1, display: "flex", flexDirection: "column", overflowX: "auto", overflowY: "auto"}}>
					<Component onClose={setChildOnClose} context={context} item={item}/>
				</div>
			</>
		)
	}, [RealOnClose, context, item])
}

function App(){
	const { id } = useParams()
	const [ws, setWS] = useState(new WebSocketHandle())
	const [connStatus, setConnStatus] = useState(null)
	const [session, setSession] = useState(false)

	// Layout
	const [layout, setLayout] = useState([])
	const layoutFirstLoad = useRef(true)
	const layoutSnapShot = useRef([])

	// Right Click Menu
	const [ShowMenu, setShowMenu] = useState(null)
	// Toasts
	const [Toasts, setToasts] = useState([])
	const AddToast = useCallback((toast)=>{ setToasts((old)=>[...old, toast])}, [])

	// Global Control
	const [GlobalSubscribe, setGlobalSubscribe] = useState(null)
	const GlobalSubscribeObj = useMemo(()=>{ return {state: GlobalSubscribe, setState: setGlobalSubscribe} }, [GlobalSubscribe])

	// Data
	const [GeneralMessageData, setGeneralMessageData] = useState([
		new Message(new Date(), "Page Start.")
	])
	const [SeriesDefinition, setSeriesDefinition] = useState({})
	const SeriesDefinitionRef = useRef({})
	const OBID2Symbol = useRef({})
	const [MonthCodes, setMonthCodes] = useState([])
	const [QuoteData, setQuoteData] = useState({})
	const [RealtimeQuoteData, setRealtimeQuoteData] = useState({})
	const QuoteDataRef = useRef({lastTime: 0, quotes:{}})
	const [MarketTradesData, setMarketTradesData] = useState({})
	const MarketTradeDataRef = useRef({lastTime: 0, trades:{}})
	const [PositionData, setPositionData] = useState({})
	const ProfitCalculatorRef = useRef(new ProfitCalculator())
	const [OrdersData, setOrdersData] = useState([])
	const [TradesData, setTradesData] = useState([])
	const [HistoryEOF, setHistoryEOF] = useState(false)

	useEffect(()=>{
		if (!ws.ws)
		{
			const yearCode = new Date().getFullYear() % 10
			const productList = []
			ws.connect(setWS, setConnStatus, (msg)=>{
				const jsonData = JSON.parse(msg)
				if (jsonData.e === "Resume")
				{
					if (jsonData.status === StatusType.Success)
					{
						setSession(localStorage.userID)
						ws.send(JSON.stringify({e: "Trading", subE: "GetSeriesDefinition"}))
						ws.send(JSON.stringify({e: "Trading", subE: "GetHistory"}))
					}
					else
					{
						delete(localStorage.userID)
						window.location = "/login"
					}
				}
				else if (jsonData.e === "Trading")
				{
					if (jsonData.subE === "SeriesDefinition")
					{
						if (jsonData.status === StatusType.EOF)
						{
							setMonthCodes(productList.sort((a,b)=>{return a.expireDate - b.expireDate}).slice(0, 5).map((x)=>{
								return {
									code: x.symbol.substr(3,2),
									dateStr: (x.expireDate.getFullYear() + "/" + ("0"+(x.expireDate.getMonth()+1)).slice(-2))
								}
							}))

							setSeriesDefinition(SeriesDefinitionRef.current)
							return
						}

						jsonData.definition.decimal = new Decimal(10).pow(jsonData.definition.numOfDecimals).toNumber()
						jsonData.definition.expireDate = new Date(jsonData.definition.expireDate.substr(0, 4) + "-" + jsonData.definition.expireDate.substr(4, 2) + "-" + jsonData.definition.expireDate.substr(6, 2) + "T23:59:59+08:00")
						SeriesDefinitionRef.current[jsonData.definition.symbol] = jsonData.definition
						OBID2Symbol.current[jsonData.definition.obid] = jsonData.definition.symbol

						if (jsonData.definition.symbol.startsWith("HSI") && jsonData.definition.productType === 3)
						{
							productList.push(jsonData.definition)
						}
					}
					else if (jsonData.subE === "Quote")
					{
						const symbol = OBID2Symbol.current[jsonData.quote.obid]
						const definition = SeriesDefinitionRef.current[symbol]

						jsonData.quote.bid = new Decimal(jsonData.quote.bid).div(definition.decimal).toNumber()
						jsonData.quote.ask = new Decimal(jsonData.quote.ask).div(definition.decimal).toNumber()
						if (jsonData.quote.tradeP)
						{
							jsonData.quote.tradeP = new Decimal(jsonData.quote.tradeP).div(definition.decimal).toNumber()
							jsonData.quote.last = jsonData.quote.tradeP
							jsonData.quote.lastS = jsonData.quote.tradeS
						}

						const history = QuoteDataRef.current.quotes[symbol]
						const current = Object.assign({}, history, jsonData.quote)
						QuoteDataRef.current.quotes[symbol] = current
						if (history)
						{
							if (current.bid !== 0)
							{
								if (!current.open) current.open = jsonData.quote.bid
								current.low = Math.min(history.low??jsonData.quote.bid, jsonData.quote.bid)
								current.high = Math.max(history.high??jsonData.quote.bid, jsonData.quote.bid)
							}
							if (current.tradeS)
								current.volume += current.tradeS
						}
						else
						{
							if (current.bid > 0){
								current.open = current.bid
							}
							current.volume = current.tradeS??0
						}
						setRealtimeQuoteData(Object.assign({}, QuoteDataRef.current.quotes))

						const currTime = new Date().getTime()
						if (currTime - QuoteDataRef.current.lastTime > 499){
							setQuoteData(Object.assign({}, QuoteDataRef.current.quotes))
							QuoteDataRef.current.lastTime = currTime
						}

						if (jsonData.quote.tradeS)
						{
							if (!MarketTradeDataRef.current.trades[symbol])
								MarketTradeDataRef.current.trades[symbol] = []
							MarketTradeDataRef.current.trades[symbol] = [
								...MarketTradeDataRef.current.trades[symbol].slice(-99),
								new Trade({time: currTime, symbol: symbol, side: void 0, price: jsonData.quote.tradeP, volume:jsonData.quote.tradeS})
							]

							if (currTime - MarketTradeDataRef.current.lastTime > 499){
								setMarketTradesData(Object.assign({}, MarketTradeDataRef.current.trades))
								MarketTradeDataRef.current.lastTime = currTime
							}
						}
					}
					else if (jsonData.subE === "Order")
					{
						const order = new Order(jsonData.order)
						const definition = SeriesDefinitionRef.current[order.symbol]
						order.price = new Decimal(order.price).div(definition.decimal).toNumber()

						if (jsonData.status === StatusType.Success){
							setOrdersData((last)=>[...last, order])
							setGeneralMessageData((last)=>[
								...last, new Message(new Date().getTime(), "Order: "+order.symbol+" | "+ ID2OrderType[order.orderType]+ " | "+(order.side===OrderSide.Buy?"B":"S"))
							])
							AddToast(
								<AutoHideToast delay={2000} title='Order Accepted'
									message={"Symbol: " + order.symbol + " | Type: " + ID2OrderType[order.orderType] + " | Side: " + (order.side===OrderSide.Buy?"B":"S")}
								/>
							)
						}
						else if (jsonData.status === StatusType.LoadHostory)
						{
							setOrdersData((last)=>[...last, order])
						}
						else if (jsonData.status === StatusType.NotEnoughPosition)
						{
							setGeneralMessageData((last)=>[
								...last, new Message(new Date().getTime(), "Reject: "+order.symbol+" | "+(order.side===OrderSide.Buy?"B":"S")+" | NotEnoughPosition")
							])
							AddToast(
								<AutoHideToast delay={2000} title='Not Enough Position' type='error'
									message={"Symbol: " + order.symbol + " | Type: " + ID2OrderType[order.orderType] + " | Side: " + (order.side===OrderSide.Buy?"B":"S")}
								/>
							)
						}
					}
					else if (jsonData.subE === "Trade")
					{
						if (jsonData.status === StatusType.EOF)
						{
							setHistoryEOF(true)
							return 
						}
						const trade = new Trade(jsonData.trade)
						const definition = SeriesDefinitionRef.current[trade.symbol]
						trade.price = new Decimal(trade.price).div(definition.decimal).toNumber()

						ProfitCalculatorRef.current.ProcessTrade(trade)
						setPositionData(Object.assign({}, ProfitCalculatorRef.current.assets))
						setOrdersData((last)=>last.filter((x)=>x.oid!==trade.oid))
						setTradesData((last)=>[...last, trade])

						if (jsonData.status === StatusType.Success)
						{
							
							AddToast(
								<AutoHideToast delay={2000} title='Trade Completed'
									message={"Symbol: " + trade.symbol + " | Side: " + (trade.side===OrderSide.Buy?"B":"S") + " | Price: " + (trade.price)}
								/>
							)
						}
					}
					else if (jsonData.subE === "GetHistory")
					{
						if (jsonData.status === StatusType.EOF) setHistoryEOF(true)
					}
				}
			})
		}
		else if (connStatus === true)
		{
			if (!localStorage.userID)
			{
				window.location = "/login"
			}
			ProfitCalculatorRef.current = new ProfitCalculator()
			setHistoryEOF(false)
			setTradesData([])
			ws.send(JSON.stringify({e: "Resume", userID: localStorage.userID}))
		}
	}, [AddToast, ws, connStatus])

	useEffect(() => {
		if (layoutFirstLoad.current === false){
			const saveingLayout = layout.sort((a, b)=>{
				if (a.pos.y > b.pos.y)
					return 1
				if (a.pos.y < b.pos.y)
					return -1
				return 0
			})

			layoutSnapShot.current = saveingLayout
			window.layoutSnapShot = saveingLayout
		}else{
			setTimeout(()=>{
				setLayout(loadLayout(id))
				layoutFirstLoad.current = false
			}, 150)
		}
	}, [layout])

	const ShowLayout = useMemo(()=>{
		return (
			<ResponsiveGridLayout style={{display: "block"}} draggableCancel=".react-draggable-cancel" draggableHandle={".react-draggable-handle"} className="layout" rowHeight={4} margin={[2,2]}
				breakpoints={{xlg: 1600, lg: 1200, md: 1000, sm: 800, xs: 650, xxs: 0}}
				cols={{xlg: 16, lg: 12, md: 10, sm: 8, xs: 5, xxs: 3}}
				onLayoutChange={(newLayout)=>{
					if (layoutFirstLoad.current === false){
						setLayout((last)=>{
							let newArr = [];
							newLayout.forEach((x)=>{
								const key = parseInt(x.i)
								const item = last.filter((x)=>{
									if (x.key === key)
										return true
									return false
								})[0]

								let thisItem = {
									key: item.key,
									pos: {x: x.x, y: x.y, w: x.w, h: x.h},
									component: item.component,
									rows: item.rows
								}
								newArr.push(thisItem)
							})
							return newArr
						})
					}
				}}
			>
				{
					layout.map((item) => {
						return (
							<div key={item.key} data-grid={PosWithMinSize(item)}
								style={{border: "1px solid", borderRadius: 4, backgroundColor: "white", display: "flex", flexDirection: "column"}}
							>
								<ShowComponent onClose={()=>{
									setLayout((last)=>{ return last.filter((x)=>{ return x.key !== item.key }) })
								}} context={MainContext} item={item}/>
							</div>
						)
					})
				}
			</ResponsiveGridLayout>
		)
	}, [layout, MainContext])

	const ShowToasts = useMemo(()=>{
		return (
			<div style={{zIndex: 10000, position: "fixed", bottom: 8, right: 8, display: "flex", gap: 8, flexDirection: "column"}}>
				{ Toasts.map((x, i)=>{ return <React.Fragment key={i}>{x}</React.Fragment> }) }
			</div>
		)
	}, [Toasts])

	const SendSubscribe = useCallback((symbol)=>{
		if (Object.keys(SeriesDefinition).length === 0) return
		const definition = SeriesDefinition[symbol]
		if (definition)
		{
			ws.send(JSON.stringify({e: "Trading", subE: "Subscribe", OBID: definition.obid}))
		}
	}, [ws, SeriesDefinition])

	return (
		<div style={{height: "100vh", width: "100vw"}} onClick={(e)=>{
			setShowMenu(null)
		}}>
			<PopUpStatusModal connStatus={connStatus} SeriesDefinition={SeriesDefinition} HistoryEOF={HistoryEOF}/>
			<TopNav ws={ws} session={session} setLayout={setLayout} />
			{ShowMenu}
			{ShowToasts}
			<MainContext.Provider value={{
				// Layouts
				setShowMenu: setShowMenu,
				AddToast: AddToast,
				// Global Control
				GlobalSubscribe: GlobalSubscribeObj,
				//Data:
				SeriesDefinition: SeriesDefinition,
				MonthCodes: MonthCodes,
				GeneralMessageData: GeneralMessageData,
				QuoteData: QuoteData,
				RealtimeQuoteData: RealtimeQuoteData,
				MarketTradesData: MarketTradesData,
				PositionData: PositionData,
				OrdersData: OrdersData,
				TradesData: TradesData,
				//Function
				SendSubscribe: SendSubscribe,
				ws: ws
			}}>
				{ShowLayout}
			</MainContext.Provider>
		</div>
	);
}

export default App