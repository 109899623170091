function ParseBigNumber(num){
    if (Math.abs(num) >= 1000000)
    {
        return Math.round(num / 10) / 100 + "M"
    }
    if (Math.abs(num) >= 1000)
    {
        return Math.round(num / 10) / 100 + "K"
    }

    return num
}

export { ParseBigNumber }