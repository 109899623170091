const StatusType = {
    Success:            0,
    Error:              1,
    MultipleSession:    2,
    MaxSession:         3,
    EOF:                1000,
    LoadHostory:        1001,

    SQLError:           5000,
    ServerTimeout:      5001,

    NotEnoughCredit:    9000,
    NotEnoughPosition:  9001,
}

const ExpireType2ID = {
	FAK: 0,
	Today: 1,
	UntilCancel: 2,
	Days: 5
}

const ID2ExpireType = {
	0: "Fill And Kill",
	1: "Rest Of Day",
	2: "Until Cancel",
	5: "Days"
}

const OrderType2ID = {
	Limit: 1,
	Market: 2,
	StopLimit: 3,
	UpTrigger: 4,
	DownTrigger: 5,
	OCO: 6
}

const ID2OrderType = {
	1: "Limit",
	2: "Market",
	3: "Stop-Limit",
	4: "Up Trigger",
	5: "Down Trigger",
	6: "OCO"
}

const OrderType2ExpireType = {
	1: [ExpireType2ID.Today, ExpireType2ID.Days, ExpireType2ID.FAK, ExpireType2ID.UntilCancel],
	2: [ExpireType2ID.FAK],
	3: [ExpireType2ID.Today, ExpireType2ID.Days, ExpireType2ID.UntilCancel],
	4: [ExpireType2ID.Today, ExpireType2ID.Days, ExpireType2ID.UntilCancel],
	5: [ExpireType2ID.Today, ExpireType2ID.Days, ExpireType2ID.UntilCancel],
	6: [ExpireType2ID.Today, ExpireType2ID.Days, ExpireType2ID.UntilCancel]
}

const OrderSide = {
	Buy: 1,
	Sell: 2
}

export {StatusType, ExpireType2ID, ID2ExpireType, OrderType2ID, ID2OrderType, OrderType2ExpireType, OrderSide}