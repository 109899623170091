import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { WebSocketHandle } from '../WebSocket';

import { StatusType } from "../functions/API"

import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';

function LoginPage(){
    const { reason } = useParams()

    const [ws, setWS] = useState(new WebSocketHandle())
    const [connState, setConnState] = useState(false)

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [errMsg, setErrMsg] = useState("")

    useEffect(()=>{
        if (reason === "logout" || reason === "forceLogout"){
            delete(localStorage.userID)
            Object.keys(localStorage).forEach((x)=>{
                if (x.indexOf("sessionID-") === 0)
                    delete(localStorage[x])
            })
        }
    }, [reason])

    useEffect(()=>{
        if (!ws.ws){
            ws.connect(setWS, setConnState, (msg)=>{
                console.log(msg)
                const jsonData = JSON.parse(msg)
                if (jsonData.e === "Login"){
                    if (jsonData.status === StatusType.Success){
                        localStorage.userID = jsonData.userID
                        ws.close()
                        window.location = "/main"
                    }
                    else if (jsonData.status === StatusType.Error){
                        setErrMsg("Username or Password Wrong!")
                    }
                    else if (jsonData.status === StatusType.ServerTimeout){
                        setErrMsg("Server Timeout.")
                    }
                }
            })
        }
    }, [ws])

    const formHandle = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
        if (username !== "" && password !== "" && ws){
            ws.send(JSON.stringify({e: "Login", username, password}))
        }
        else{
            if (username === "")
                setErrMsg("Username is required.")
            else if (password === "")
                setErrMsg("Password is required.")
        }
    }, [username, password, ws])

    useEffect(()=>{
        if (connState !== true){
            setErrMsg("Server isn't connected." + (connState===false?"":connState>0?" Retry in "+connState/1000+" seconds.":" Retry!"))
        }else if (connState){
            setErrMsg("")
        }
    }, [connState, username, password])

    return (
        <Card style={{margin: "5em auto", maxWidth: 650}}>
            <Card.Header className="bg-primary text-white">
                <span className="fs-3">Login</span>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={formHandle}>
                    <Form.Group className="mb-4">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" isInvalid={errMsg.indexOf("Username")>=0} onChange={(v)=>{
                            setUsername(v.currentTarget.value)
                        }}/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" isInvalid={errMsg.indexOf("Password")>=0} onChange={(v)=>{
                            setPassword(v.currentTarget.value)
                        }}/>
                    </Form.Group>
                    <p className="mb-4" style={{display: errMsg!==""?"block":"none", color: "red"}}>{errMsg}</p>
                    <Button type="submit">Login</Button>
                    <Button style={{marginLeft: 4}} onClick={()=>{window.location = "/qrcodelogin"}}>QRCode Login</Button>
                </Form>
            </Card.Body>
            <Card.Footer style={{display: reason?"block":"none"}}>
                <span style={{display: reason?"block":"none", color: "red"}}>{reason==="forceLogout"?"Someone logged in from other device or browser. Please login again.":reason==="maxSession"?"Sessions more than allowed.":reason==="logout"?"Logout Success.":""}</span>
            </Card.Footer>
        </Card>
    );
}

export default LoginPage