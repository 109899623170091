const RegexHandle = {
    Interger: /^[\d]+$/,
    ReplaceInterger: (value = "")=>{
        return value.replace(/[^\d]/g, "")
    },
    Float: /([0-9]*[.])?[0-9]+/,
    ReplaceFloat: (value = "", lastValue)=>{
        let replaceNotFloat = value.replace(/[^\d.]/g, "")
        const splitByFloat = replaceNotFloat.split(".")
        
        if (splitByFloat.length > 2) return lastValue
        if (splitByFloat.length === 1) return splitByFloat[0]
        return splitByFloat[0] + "." + splitByFloat[1]
    }
}

export default RegexHandle