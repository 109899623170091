import Decimal from "decimal.js"

import { OrderSide } from "./API"
import { Trade } from "./Classes"

class ProfitCalculator
{
    constructor(){
        this.assets = {}
    }

    ProcessTrade(trade=new Trade())
    {
        if (trade.side === OrderSide.Buy)
        {
            const asset = Object.assign({}, this.assets[trade.symbol]??{symbol: trade.symbol, cost_: new Decimal(0), pos_: new Decimal(0), pnl_: new Decimal(0), to_: new Decimal(0)})


            asset.cost_ = asset.cost_.mul(asset.pos_).plus(new Decimal(trade.price).mul(trade.volume)).div(asset.pos_.plus(trade.volume))
            asset.pos_ = asset.pos_.plus(trade.volume)
            asset.to_ = asset.to_.plus(new Decimal(trade.price).mul(trade.volume))

            asset.cost = asset.cost_.toNumber()
            asset.pos = asset.pos_.toNumber()
            asset.pnl = asset.pnl_.toNumber()
            asset.to = asset.to_.toNumber()

            this.assets[trade.symbol] = asset
        }
        else if (trade.side === OrderSide.Sell)
        {
            const asset = Object.assign({}, this.assets[trade.symbol])

            const thisPnL = new Decimal(new Decimal(trade.price).minus(asset.cost_)).mul(trade.volume)
            trade.pnl = thisPnL.toNumber()
            asset.pnl_ = asset.pnl_.plus(trade.pnl)
            asset.pos_ = asset.pos_.minus(trade.volume)
            if (asset.pos_.toNumber() === 0) asset.cost_ = new Decimal(0)
            asset.to_ = asset.to_.plus(new Decimal(trade.price).mul(trade.volume))

            asset.cost = asset.cost_.toNumber()
            asset.pos = asset.pos_.toNumber()
            asset.pnl = asset.pnl_.toNumber()
            asset.to = asset.to_.toNumber()

            this.assets[trade.symbol] = asset
        }
    }
}

export default ProfitCalculator