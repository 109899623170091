import { useMemo } from "react"

import "../css/SubPageBtn.css"

function SubPageBtnList({btnKey=["key"], btnName=["Name"], selectedKey="key", setSelectedKey, color="black", bgColor="#ccc"})
{
    return useMemo(()=>{
        const Style = {"--tooltip-bgcolor": bgColor, "--tooltip-color": color}

        return (
            <div className="SubPageBtnList">
                {
                    btnKey.map((x, i)=>{
                        if (x === selectedKey)
                            return <button key={x} className="SubPageBtn Selected" style={Style} onClick={()=>{setSelectedKey(x)}}>{btnName[i]}</button>
                        return <button key={x} className="SubPageBtn" style={Style} onClick={()=>{setSelectedKey(x)}}>{btnName[i]}</button>
                    })
                }
            </div>
        )
    }, [btnKey, btnName, selectedKey, setSelectedKey, color, bgColor])
}

export default SubPageBtnList